/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        anchoVentana = $(window).width();
        altoVentana = $(window).height();
        timeout = false;
        delta = 200;
        checkMobile();

        $('.texto_gastos_minimos').hide();

        $('.spin').spin('hide');

        $('[data-toggle="tooltip"]').tooltip();

        $('.int-buscador').on('keypress', function(e){
          tecla=(document.all) ? e.keyCode : e.which;
          if(tecla==13){
            $('#fBusqueda').submit();
          }
        });
        $('.js-activar-menu-mbl').click(function(){
            $('.js-menu-mbl-usuario').toggleClass('activo');
        });


        $(".js-btn-menu").click(function(){
          $(".menu-principal").toggleClass("activo");
          $(".contenedor-principal").toggleClass("activo");
          $(this).toggleClass("activo");
        });

/**
 * gestión menú para desktop con hover
 */
        $(".menu-principal .item-menu").hover(function(){
          if(anchoVentana>767){
            $(this).addClass("abierto");
            $(this).find(".submenu").show();
          }
        },function(){
          if(anchoVentana>767){
            var obj=$(this)
            $(this).removeClass("abierto");
            intMenu=setTimeout(function(){
              if(!obj.hasClass("abierto")){
                obj.find(".submenu").fadeOut("fast");
             }
            },250)
          }
        });
        $(".submenu").hover(function(){
          if(anchoVentana>767){
            $(this).parent().addClass("abierto");
          }
        });

/**
 * gestión menú para mobile con click
 */
        $(".menu-principal .item-menu").on("click touchstart",function(e){
          if(anchoVentana<=767){
            if($(this).find('.submenu').length != 0){
              e.preventDefault()
              $(this).addClass("abierto");
            }
          }
        });
        $(".menu-principal .item-menu .item-submenu").on("click touchstart",function(e){
          if(anchoVentana<=767){
            document.location.href=$(this).attr("href");
          }
        });
        $(".js-close-submenu").on("click touchstart",function(e){
          // console.log($(this).closest(".item-menu").attr("class"));
          $(this).closest(".item-menu").removeClass("abierto");
          e.stopPropagation();
        });


        $("#txtbusqueda").on("paste keyup", function() {

          if ($(this).val().length>3){
            try {
                clearTimeout(timeOutPredictivo);
            }
            catch(err) {
            }

            timeOutPredictivo = setTimeout(function(){

              $.ajax({
                url: ajaxTheme,
                type: "POST",
                data: "action=busquedaPredictiva&txtbusqueda="+$("#txtbusqueda").val(),
                success: function (response) {

                  if (response!=""){
                    $(".resultado_busqueda_predictiva").html(response);
                    $(".modal-busqueda").fadeIn();
                  }else{
                    $(".resultado_busqueda_predictiva").html("No se han encontrado resultados.");
                    $(".modal-busqueda").fadeIn();
                  }


                },
                error: function (xhr, status) {
                },
                complete: function (xhr, status) {
                }
              });

            }, 200);
          }else{

            try {
                clearTimeout(timeOutPredictivo);
            }
            catch(err) {
            }
            $(".modal-busqueda").fadeOut();
          }

        })
/*gestión cookies*/
// cookies banner
        // $('#cookie-banner').css('display','block').animate({bottom: '0px'});

        $('#cookie-banner .js-acepto-cookie').on('click', function(e){
          //hoy + 30 días
          /*var date = new Date;
          date.setDate(date.getDate() + 30);          
          document.cookie="www-gruposolera-es-eu-cookie=1; expires="+date+";";
          $('#cookie-banner').hide();*/


          var d = new Date();
          d.setTime(d.getTime() + (30*24*60*60*1000));
          var expires = "expires="+d.toUTCString();
          document.cookie = "www-gruposolera-es-eu-cookie=1; " + expires;
          $('#cookie-banner').hide();
        });


        $(".js-carrito").hover(function(){
          if(anchoVentana>767){
            if (cestaVacia()){
                alerta_carrito('No hay artículos en tu carrito');
            }
            else{

              recargarCesta(_cestaCarrito);
              $(this).addClass("abierto");
              $(".modal-articulos-carrito").addClass("activo");
            }
          }
        },function(){
          if(anchoVentana>767){
            var obj=$(this);
            $(this).removeClass("abierto");
            intMenu=setTimeout(function(){
              if(!obj.hasClass("abierto")){
                $(".modal-articulos-carrito").removeClass("activo");
             }
            },250)
          }
        });

        $(".modal-articulos-carrito").hover(function(){
            $(this).parent().find(".js-carrito").addClass("abierto");
        },function(){
          var obj=$(".js-carrito");
          $(".js-carrito").removeClass("abierto");
          intMenu=setTimeout(function(){
            if(!obj.hasClass("abierto")){
              $(".modal-articulos-carrito").removeClass("activo");
           }
          },250)          
        });

        
        $('#cerrar-busqueda').on('click', function(){
          $('.modal-busqueda').fadeOut();
        });

        $(".js-btn-suscrip-news-pie").click(function(event) {

          emailfooter = $(this).closest('#suscripcionNewsPie').find('#emailsuscripcionnewsletterfooter').val();
          ck =  $(this).closest('#suscripcionNewsPie').find('.css-checkbox');
          
        
          //if ( !validateEmail($("#suscripcionNewsPie #emailsuscripcionnewsletterfooter").val()) ||  !$("#suscripcionNewsPie #inputcheckbox").is(":checked")){
            if (!validateEmail(emailfooter) || !ck.is(":checked")){
            alerta($("#suscripcionNewsPie .alert"),"alert-warning","Comprueba tu email y que has aceptado la política de privacidad.",false);

          }else{

            $.ajax({
              url: ajaxTheme,
              type: "POST",
              dataType: "json",
              //data: $("#suscripcionNewsPie").serialize(),
              data:"&action=suscripcionNewsletter&emailsuscripcionnewsletterfooter="+emailfooter,
              success: function (response) {
                if (response.resultado=="OK"){

                  alerta($("#suscripcionNewsPie .alert"),"alert-success",response.msg,true);
                  //$("#suscripcionNewsPie #emailsuscripcionnewsletterfooter").val("");
                  //$("#suscripcionNewsPie #inputcheckbox").attr('checked', false);
                  $(this).closest('#suscripcionNewsPie').find('#emailsuscripcionnewsletterfooter').val('');
                  $(this).closest('#suscripcionNewsPie').find('#inputcheckbox').attr('checked', false);
                }else{

                  alerta($("#suscripcionNewsPie .alert"),"alert-warning",response.msg,false);

                }

              },
              error: function (xhr, status) {
              },
              complete: function (xhr, status) {
              }
            });          
          }

        });

        $(".js-videomodal").on('click', function(){
          /*var mp4=$(this).data("video-mp4");
          var ogv=$(this).data("video-ogv");
          var webm=$(this).data("video-webm");
          var html='<video width="100%" height="100%" controls autoplay><source src="'+mp4+'" type="video/mp4"><source src="'+ogv+'" type="video/ogg"><source src="'+webm+'" type="video/webm"></video>';
          */
          var _idvideo = $(this).data('video-id');
          var html = '<div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" src="//www.youtube.com/embed/'+_idvideo+'?rel=0" allowfullscreen></iframe></div>';
          mostrar_msg('#modal-video', '', html); 

        });


        $(".js-logout").click(function(event) {
              $.ajax({
                url: ajaxTheme,
                type: "POST",
                dataType: "json",
                data: "action=logoutUsuario",
                success: function (response) {
                  if (response.resultado=="OK"){

                    document.location.href=ruta;

                  }

                },
                error: function (xhr, status) {
                },
                complete: function (xhr, status) {
                }
              });
        });



        //calcularGastosEnvioEstimado();
        

          $("#formCalcularGastosEnvio .js-pais").change(function(event) {
            $("#formCalcularGastosEnvio .js-provincia").html("");

            $("#formCalcularGastosEnvio .js-provincia").append('<option class="item-provincia" selected disabled value="">Selecciona la provincia de entrega</option>');

              provinciasPais=JSON.parse($(this).find(":selected").attr("data-provincias"));

            jQuery.each(provinciasPais, function(i, val) {
              $("#formCalcularGastosEnvio .js-provincia").append('<option class="item-provincia" value="'+val.nombre+'" data-precio="'+val.coste+'" >'+val.nombre+'</option>');
            });
            $("#CosteResultadoGastosEnvio").html('--');

          });


          $("#formCalcularGastosEnvio .js-provincia").change(function(event) {
            calcularGastosEnvioEstimado();
            //$("#CosteResultadoGastosEnvio").html($(this).find(":selected").attr("data-precio"));
            $(".resultadoGastosEnvio").removeClass('sr-only');


          });


          jQuery(".js-btnAyuda").click(function(event) {
            jQuery("#modalAsistente").fadeToggle();
          });

          jQuery("#modalAsistente .close").click(function(event) {
            jQuery("#modalAsistente").fadeToggle();
          });


          jQuery("#preguntaAsistente").keyup(function(e){ 
            e.preventDefault();
              var code = e.which; // recommended to use e.which, it's normalized across browsers

              if(code==13){

                jQuery(".js-respuestaAsistente").slideUp();
                jQuery(".js-urlAsistente").hide();

                    jQuery.ajax({
                      url: ajaxTheme,
                      type: "POST",
                      dataType: "json",
                      data: jQuery("#formularioAsistente").serialize(),
                      success: function (response) {

                        jQuery(".js-respuestaAsistente").html(response.respuesta);
                        jQuery(".js-respuestaAsistente").slideDown();
                        if (response.url!=""){
                          
                          jQuery(".js-respuestaAsistente").append('<button redirect="javascript:;" type="button" data-loading-text="..." class="btn btn-block btn-lg btn-asistente js-urlAsistente urlAsistente"  id="boton-asistente" style="display:none;"><strong>Pincha aquí para ver más</strong>');  
                          jQuery(".js-urlAsistente").attr("redirect",response.url);
                          jQuery(".js-urlAsistente").show();
                          //jQuery(".js-urlAsistente").bind('click');
                          jQuery(".js-urlAsistente").off('click');
                          jQuery(".js-urlAsistente").on('click', function(){
                            document.location.href=$(this).attr('redirect');
                          });
                        }

                      },
                      error: function (xhr, status) {
                      },
                      complete: function (xhr, status) {
                      }
                    });

              }
          });

          
/*
        //Hace responsive los tabs
        (function(jQuery) {
            fakewaffle.responsiveTabs(['xs', 'sm']);
        })(jQuery);
  */        
              
               // Initialize mCustomScrollbar
          $(".contenedor-soportes").mCustomScrollbar({
            theme:"3d-thick-dark",
            scrollbarPosition: "outside",
            scrollButtons:{ enable: true }
          });

          /**
           * despliega menu footer mobile
           */
           $(".js-menu-footer").click(function(){
              $(this).find(".submenu").slideToggle();
              $(this).find("li .fa").toggleClass("fa-minus");
           });
          /**
           * despliega buscador mobile
           */
           $(".js-buscador-mb-ico").click(function(){
              $(".seccion-buscador").fadeToggle();
              $(".seccion-buscador input").focus();
           });




           /*Capturar el click del boton deja que te llamemos para cargar el modal asincrono antes*/
           $('a[data-target="#modal-llamar"], a[href="#modal-llamar"]').click(function(event) {           
             if ( $( "#modal-llamar" ).length ) {
                console.log('ya esta cargada, no hago nada');
             }else{
                event.preventDefault(); //paro el evento para que no intente mostrarlo antes de que lo cargue
                console.log('primero la cargo por ajax');
                $.get( global.template_url+"/solera-templates/098-deja-que-te-llamemos.php", function( data ) {
                  console.log('cargado');
                  console.log(data);
                  $( 'body' ).append( data );
                  $('#modal-llamar').modal('show');
                });
             }
           });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var mySwiper = new Swiper ('.swiper-container', {
              // Optional parameters
              direction: 'horizontal',
              loop: true,
              
              autoplay: 5000,
              // If we need pagination
              pagination: '.swiper-pagination',
              paginationClickable: true,
             
              // Navigation arrows
            /*  nextButton: '.swiper-button-next',
              prevButton: '.swiper-button-prev',*/

              parallax: true,

            });    
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },


    'single_maquinaria':
    {
      init: function() {
        cargar_items();
         
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
     'single_productos':
    {
      init: function() {
        cargar_items();
        var galleryTop = new Swiper('.gallery-top', {
              spaceBetween: 10,
          });
        var galleryThumbs = new Swiper('.gallery-thumbs', {
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            spaceBetween: 10,
            centeredSlides: true,
            slidesPerView: 'auto',
            touchRatio: 1,
            slideToClickedSlide: true,
            autoplay: 6000,
            autoplayDisableOnInteraction: false
        });
        galleryTop.params.control = galleryThumbs;
        galleryThumbs.params.control = galleryTop;
        

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'single_accesorios':
    {
      init: function() {
        cargar_items();
        var galleryTop = new Swiper('.gallery-top', {
              spaceBetween: 10,
          });
          var galleryThumbs = new Swiper('.gallery-thumbs', {
              nextButton: '.swiper-button-next',
              prevButton: '.swiper-button-prev',
              spaceBetween: 10,
              slidesPerView: 'auto',
              touchRatio: 1,
              slideToClickedSlide: true
          });
          galleryTop.params.control = galleryThumbs;
          galleryThumbs.params.control = galleryTop;
         
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_004_listado_promociones': {
      init: function() {
        // JavaScript to be fired on all pages
          
          $('.vermas').on('click', function(){
            _href=$(this).data('href');
            document.location.href=_href;
          });

          $('.item-promociones').on('click', function(){
            _href=$(this).data('href');
            document.location.href=_href;
          });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'page_template_006_paginas_genericas': {
      init: function() {
        // JavaScript to be fired on all pages
          
          var map = null;

          $('.acf-map').each(function(){

            // create map
            map = new_map( $(this) );
            google.maps.event.trigger(map, 'resize');

          });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'page_template_002_listado': {
      init: function() {
        // JavaScript to be fired on all pages
         // _tmp = phpVars.array_taxonomias.replace('""', '"');
          _tmp = phpVars.array_taxonomias.replace("[", "");

          _tmp = _tmp.replace("]", "");
          _t = _tmp.split(",");
          var _idformato = phpVars.idformato;
          var _familia_activa = phpVars.familia_activa;
          
          var _array_taxonomias = _t;

          initListado(_array_taxonomias, _familia_activa);
  
          $('.checkbox-filtrar').on('change', function (){
                //$('#cktodos').prop('checked', false);
                filtrar(_array_taxonomias);
                if(anchoVentana<=767){
                  // $(".mod-filtro").removeClass("abierto");
                }
          });

            $('#ordenaciones').on('change', function(){
              
              switch ($(this).val()){
                case 'relevance':
                    tinysort('div.col-item-listado',{data:'valoracion', order:'desc'});
                  break;
                case 'price-asc':
                    tinysort('div.col-item-listado',{data:'precio', order:'asc'});
                  break;
                case 'price-desc':
                    tinysort('div.col-item-listado',{data:'precio', order:'desc'});
                  break;
                default:
                    tinysort('div.col-item-listado',{data:'valoracion', order:'desc'});
                  break;          
              } 
            });

            $('.ckcomparar').on('change', function(){
            if ($('#collapseFour').attr('aria-expanded')){
              
            }
            else{
              //alert(jQuery('#collapseFour').attr('aria-expanded'));
              $('#collapseComparador').trigger('click');
            }

              _producto = $(this).data('producto');
              _idproducto = $(this).data('idproducto');
              if ($(this).prop('checked') ){

                //jQuery('.item-listado').addClass('comparar'); 
                $(this).parent().parent().addClass('comparar'); 
                if ( $('#productos-comparador li').length < 3 ) {
                  
                  $('#productos-comparador').append('<li id="li-producto-'+_idproducto+'" data-idproducto="'+_idproducto+'">'+_producto+'</li>');
                }
                else{
                  mostrar_msg('#myModal', 'UPS!', 'Has llegado al límite de productos para comparar.'); 
                  $(this).prop('checked', false);
                }
              } 
              else {
                $('#li-producto-'+_idproducto).remove();
                $(this).parent().parent().removeClass('comparar');
              }
              
            });

            $('#btn-comparar').on('click', function(){
              limpiarComparador();

              if ( $('#productos-comparador li').length >1 ) {
                _cont = 1;
                $( ".lista-comparador li" ).each(function() {
                _id = $(this).data('idproducto');
                $('.col-comparador-'+_cont).html($('#comparador_'+_id).html());
                $('.col-capacidad-'+_cont).html($('#Capacidad_'+_id).html());
                $('.col-potencia-'+_cont).html($('#Potencia_'+_id).html());
                $('.col-peso-'+_cont).html($('#Peso_'+_id).html());
                $('.col-dimensiones-'+_cont).html($('#Dimensiones_'+_id).html());
                $('.col-equipamiento-'+_cont).html($('#Equipamiento_'+_id).html());
                
                _cont++;
              });

              if ($('#productos-comparador li').length == 2){
                  //no mostrar la última columna, queda muy raro que aparezca la cuadrícula vacía
                    $('.columna3').hide();
              }  
              else {
                    $('.columna3').show();
              }

                $('#modalComparador').modal();
              }
              else {
                mostrar_msg('#myModal', 'UPS!', 'Debes indicar al menos dos productos a comparar.');  
              } 
            });

            /**
             * evento filtro mobile
             */
            $(".js-filtro-listado").click(function(){
                $(".mod-filtro").css("top",$(".articulos-orden-listado").position().top+135);
                $(".mod-filtro").toggleClass("abierto");
                $(".mod-filtro .animacion-mas").trigger("click");
            });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'page_template_023_listado_categorias': {
      init: function() {
         // JavaScript to be fired on all pages
         // _tmp = phpVars.array_taxonomias.replace('""', '"');
          _tmp = phpVars.array_taxonomias.replace("[", "");
          _tmp = _tmp.replace("]", "");
          _t = _tmp.split(",");
          var _idformato = phpVars.idformato;
          var _familia_activa = phpVars.familia_activa;
          var _array_taxonomias = _t;
            //initListado(_array_taxonomias, _familia_activa);

            $('#ordenaciones_a').on('change', function(){
              
              switch ($(this).val()){
                case 'relevance':
                    tinysort('div.col-item-listado-a',{data:'valoracion', order:'desc'});
                  break;
                case 'price-asc':
                    tinysort('div.col-item-listado-a',{data:'precio', order:'asc'});
                  break;
                case 'price-desc':
                    tinysort('div.col-item-listado-a',{data:'precio', order:'desc'});
                  break;
                default:
                    tinysort('div.col-item-listado-a',{data:'valoracion', order:'desc'});
                  break;          
              } 
            });
            $('#ordenaciones_b').on('change', function(){
              switch ($(this).val()){
                case 'relevance':
                    tinysort('div.col-item-listado-b',{data:'valoracion', order:'desc'});
                  break;
                case 'price-asc':
                    tinysort('div.col-item-listado-b',{data:'precio', order:'asc'});
                  break;
                case 'price-desc':
                    tinysort('div.col-item-listado-b',{data:'precio', order:'desc'});
                  break;
                default:
                    tinysort('div.col-item-listado-b',{data:'valoracion', order:'desc'});
                  break;          
              } 
            });
            $('#ordenaciones_c').on('change', function(){
              switch ($(this).val()){
                case 'relevance':
                    tinysort('div.col-item-listado-c',{data:'valoracion', order:'desc'});
                  break;
                case 'price-asc':
                    tinysort('div.col-item-listado-c',{data:'precio', order:'asc'});
                  break;
                case 'price-desc':
                    tinysort('div.col-item-listado-c',{data:'precio', order:'desc'});
                  break;
                default:
                    tinysort('div.col-item-listado-c',{data:'valoracion', order:'desc'});
                  break;          
              } 
            });                        
            $(".js-filtro-listado").click(function(){
                $(".mod-filtro").css("top",$(".articulos-orden-listado").position().top+135);
                $(".mod-filtro").toggleClass("abierto");
                $(".mod-filtro .animacion-mas").trigger("click");
            });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },    
    /*'single_promociones': {
      init: function() {
        // JavaScript to be fired on all pages
          var _numProductos = phpVars.numProductos;
          var _importe_minimo = phpVars.importe_minimo;
          var _importe_permitido = phpVars.importe_permitido;
          var _promo_fija = false;
          if (phpVars.promo_fija == 1 || phpVars.promo_fija == '1' ){
              _promo_fija = true;
          }

         
          jQuery('.jqzoom').jqzoom({
            zoomType: 'standard',
            lens:true,
            preloadImages: true,
            alwaysOn:false,
            zoomWidth: 500,  
            zoomHeight: 500,
            position:'left',
            preloadText: 'Cargando zoom'
          });
          
          $(".producto-primero").trigger("click");
          var mySwiper = new Swiper ('.galeria-productos', {
                  // Optional parameters
                  direction: 'horizontal',
                  loop: true,
                  
                 // autoplay: 5000,
                  nextButton: '.swiper-button-next',
                  prevButton: '.swiper-button-prev',
                  
                 
                  spaceBetween: 5,
                  centeredSlides: false,
                  slidesPerView: 5,
                    breakpoints: {
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 20
                        },
                        990: {
                            slidesPerView: 3,
                            spaceBetween: 15
                        },
                        767: {
                            slidesPerView: 4,
                            spaceBetween: 15
                        },
                        576: {
                            slidesPerView: 3,
                            spaceBetween: 10
                        },
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 5
                        }
                    }
                 
           }); 


          initFichaPromocion(_promo_fija);


          $('.js-producto-promo_selecion').on('change', function (){
            
            _precio = $('option:selected', this).attr('data-precio');
            _precio_sin_iva = $('option:selected', this).attr('data-precio-sin-iva');
            _unidades_min = $('option:selected', this).attr('data-unidades-minimo');
            _tipo_combo = $('option:selected', this).attr('data-tipo-combo');
            
            $(this).parent().find('.cantidad-producto-promo').val('1');
            _unidades = $(this).parent().find('.cantidad-producto-promo').val();
            $(this).parent().find('.precio_ud_promo').val(_precio);


            _precio_calculo = pasarJS(_precio);
            _p = _precio_calculo*_unidades;
            _p = formatearImprimir(_p, 2);
            $(this).parent().find('.precio_total_promo').val(_p);
            
            _sabores = $('option:selected', this).attr('data-sabores');
            _a = _sabores.split('#');
            _a.pop();//quito el último
            _numero_opciones = $('option:selected', this).attr('data-numero-opciones');

            _sel = '';

            //borra posibles sabores anteriores
            objSabores=$(this).closest(".item-promo").find('.js-sabores-item');
            objSabores.html("");
            _nombre = $(this).attr('id');

            for (i=0;i<_numero_opciones;i++){
              _sel = '<div class="col-4 cont-opciones_sabores"><select name="selopciones-'+_nombre+'_'+i+'"  class="opciones_'+i+' opciones_sabores"> ';
              for (j=0;j<_a.length;j++){
                _sel += '<option value="'+_a[j]+'">'+_a[j]+'</option>'
              }
              _sel += '</select></div>';
              objSabores.append(_sel);
            }

            comprobarPromocion(_importe_minimo, _importe_permitido, _promo_fija);

          });

          $('.js-cantidad-producto-promo').on('change', function (){
            _unidades = $(this).parent().find('.cantidad-producto-promo').val();
            _precio = $(this).parent().find('.js-producto-promo_selecion option:selected').attr('data-precio');
            
            if (_precio!='' && _precio!=undefined){
              _precio_calculo = pasarJS(_precio);
              _p = _precio_calculo*_unidades;
              _p = formatearImprimir(_p, 2);
             
             
              $(this).parent().find('.precio_total_promo').val(_p);
            } 
            comprobarPromocion(_importe_minimo, _importe_permitido, _promo_fija);
            
          });

          $('.js-btn-anadir_producto').on('click', function (){

              _numProductos++;

            
              newClone = $('#producto-0').clone(true);
              //Le asigno el nuevo numero id
              newClone.attr("id",'producto-'+_numProductos);
              newClone.insertBefore($('#item-inactivo'));

              $('#producto-'+_numProductos+" .js-producto-promo_selecion").attr('id', 'producto-'+_numProductos+'-producto');
              $('#producto-'+_numProductos+" .js-producto-promo_selecion").attr('name', 'producto-'+_numProductos+'-producto');
              $('#producto-'+_numProductos+" .js-cantidad-producto-promo").attr('id', 'producto-'+_numProductos+'-unidades');
              $('#producto-'+_numProductos+" .js-cantidad-producto-promo").attr('name', 'producto-'+_numProductos+'-unidades');
              $('#producto-'+_numProductos+" .js-precio_ud_promo").attr('id', 'producto-'+_numProductos+'-precioud');
              $('#producto-'+_numProductos+" .js-precio_ud_promo").attr('name', 'producto-'+_numProductos+'-precioud');
              $('#producto-'+_numProductos+" .js-precio_total_promo").attr('id', 'producto-'+_numProductos+'-preciototal');
              $('#producto-'+_numProductos+" .js-precio_total_promo").attr('name', 'producto-'+_numProductos+'-preciototal');
              
              //console.log('prod::'+_numProductos+' '+$('#producto-'+_numProductos).html());
              $('#producto-'+_numProductos).show();
          });

          $('.js-eliminar-item').on('click', function (){
            $(this).parent().parent().remove();

            comprobarPromocion(_importe_minimo, _importe_permitido, _promo_fija);
          });

           $('#pestanya_0').addClass('show');


           $('#js-bt-add-promocion').on('click', function(){
              if ($(this).data('logado')){
                  if ($(this).data('cliente-nuevo') != 'si'){
                        mostrar_msg('#myModal', 'Lo sentimos', 'Para acceder a la promoción tienes que ser cliente nuevo.');
                  }
                  else {
                        err = comprobarPromocion(_importe_minimo, _importe_permitido, _promo_fija);
                        if (err){
                            mostrar_msg('#myModal', 'Error', 'Ocurrió un error, compruebe que está bien configurada la promoción.');
                        }
                        else {
                              addPromocion('');

                              $('.js-carrito').addClass("abierto");
                              $(".modal-articulos-carrito").addClass("activo");
                                var obj=$('.js-carrito');
                                $('.js-carrito').removeClass("abierto");
                                intMenu=setTimeout(function(){
                                  if(!obj.hasClass("abierto")){
                                    $(".modal-articulos-carrito").removeClass("activo");
                                   }
                                },2000);  
                              
                             // alerta_carrito('¡Genial!<br> Has añadido <strong>'+$(this).data('nombre-producto')+'</strong><br> a tu carrito de la compra ');

                        }
                  }
              }
              else {
                mostrar_msg('#myModal', 'Lo sentimos', 'Para acceder a la promoción tienes que estar registrado.');
              }
           });

           $('#js-bt-comprar-promocion').on('click', function(){
              if ($(this).data('logado')){
                  if ($(this).data('cliente-nuevo') != 'si'){
                        mostrar_msg('#myModal', 'Lo sentimos', 'Para acceder a la promoción tienes que ser cliente nuevo.');
                  }
                  else {
                      err = comprobarPromocion(_importe_minimo, _importe_permitido, _promo_fija);
                        if (err){
                            mostrar_msg('#myModal', 'Error', 'Ocurrió un error, compruebe que está bien configurada la promoción.');
                        }
                        else {
                              addPromocion($(this).data('href-carrito'));
                              
                            

                        }
                  }
              }
              else {
                mostrar_msg('#myModal', 'Lo sentimos', 'Para acceder a la promoción tienes que estar logado.');
              }
           });

            $('.btn-soporte').on('click', function(){
                 if ($('#selformato option:selected') .attr('stock') == '3'){
                  mostrar_msg("#myModal", 'Sin Stock', 'Producto agotado.')
                }
                else {
                    _idsoporte = $(this).data('soporte-id');
                    _descripcion_soporte = $(this).data('soporte-descripcion');
                    //alert(_descripcion_soporte);
                    _idproducto = $(this).data('producto-id');
                    _unidades_soporte = $('#selsoportes_'+_idsoporte).val();
                    addSoporteCarrito(_idsoporte, _descripcion_soporte,_unidades_soporte,_idproducto);
                }    
            });  

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },*/
   // 'page_template_005_ficha_promociones': {
    'single_promociones': {  
      init: function() {
        // JavaScript to be fired on all pages
          var _numProductos = phpVars.numProductos;
          var _importe_minimo = phpVars.importe_minimo;
          var _importe_permitido = phpVars.importe_permitido;
          var _promo_fija = false;
          if (phpVars.promo_fija == 1 || phpVars.promo_fija == '1' ){
              _promo_fija = true;
          }

         
          jQuery('.jqzoom').jqzoom({
            zoomType: 'standard',
            lens:true,
            preloadImages: true,
            alwaysOn:false,
            zoomWidth: 500,  
            zoomHeight: 500,
            position:'left',
            preloadText: 'Cargando zoom'
          });
          
          $(".producto-primero").trigger("click");
          var mySwiper = new Swiper ('.galeria-productos', {
                  // Optional parameters
                  direction: 'horizontal',
                  loop: true,
                  
                 // autoplay: 5000,
                  nextButton: '.swiper-button-next',
                  prevButton: '.swiper-button-prev',
                  
                 
                  spaceBetween: 5,
                  centeredSlides: false,
                  slidesPerView: 5,
                    breakpoints: {
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 20
                        },
                        990: {
                            slidesPerView: 3,
                            spaceBetween: 15
                        },
                        767: {
                            slidesPerView: 4,
                            spaceBetween: 15
                        },
                        576: {
                            slidesPerView: 3,
                            spaceBetween: 10
                        },
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 5
                        }
                    }
                 
           }); 


          initFichaPromocion(_promo_fija);


          $('.js-producto-promo_selecion').on('change', function (){
            
            _precio = $('option:selected', this).attr('data-precio');
            _precio_sin_iva = $('option:selected', this).attr('data-precio-sin-iva');
            _unidades_min = $('option:selected', this).attr('data-unidades-minimo');
            _tipo_combo = $('option:selected', this).attr('data-tipo-combo');
            _numproducto = $(this).attr('data-numproducto');

            if ($(this).val() == ''){
              $(this).parent().find('.cantidad-producto-promo').val('1');
              
            }  
            _unidades = $(this).parent().find('.cantidad-producto-promo').val();
            $(this).parent().find('.precio_ud_promo').val(_precio);


            _precio_calculo = pasarJS(_precio);
            _p = _precio_calculo*_unidades;
            _p = formatearImprimir(_p, 2);
            $(this).parent().find('.precio_total_promo').val(_p);
            
            _sabores = $('option:selected', this).attr('data-sabores');
            _a = _sabores.split('#');
            _a.pop();//quito el último
            _numero_opciones = $('option:selected', this).attr('data-numero-opciones');
            _numproducto = $(this).attr('data-numproducto');

            _sel = '';

            //borra posibles sabores anteriores
            objSabores=$(this).closest(".item-promo").find('.js-sabores-item');
            objSabores.html("");
            _nombre = $(this).attr('id');

            for (i=0;i<_numero_opciones;i++){
              _sel = '<div class="col-4 cont-opciones_sabores "><select id="selopciones-'+_nombre+'_'+i+'" name="selopciones-'+_nombre+'_'+i+'"  class="opciones_'+i+' opciones_sabores"> ';
              _sel += '<option value="" selected>Selecciona uno</option>';
              for (j=0;j<_a.length;j++){
                _sel += '<option value="'+_a[j]+'">'+_a[j]+'</option>'
              }
              _sel += '</select></div>';
              objSabores.append(_sel);
            }
           // alert('num:: '+_numproducto);
            comprobarOpcion(_numproducto);
            calcularTotalesPromocion(_importe_minimo, _importe_permitido);
            //comprobarPromocion(_importe_minimo, _importe_permitido, _promo_fija);

          });

          $('.js-cantidad-producto-promo').on('change', function (){
            
            if ($(this).parent().find('.js-producto-promo_selecion option:selected').val() == ''){
                _op = $(this).find("option:first-child").val();
                $(this).val(_op);
                mostrar_msg('#myModal', 'UPS!', 'Elige un producto.'); 
            }
            else{
                _unidades = $(this).parent().find('.cantidad-producto-promo').val();
                _precio = $(this).parent().find('.js-producto-promo_selecion option:selected').attr('data-precio');
                _numproducto = $(this).attr('data-numproducto');

                if (_precio!='' && _precio!=undefined){
                  _precio_calculo = pasarJS(_precio);
                  _p = _precio_calculo*_unidades;
                  _p = formatearImprimir(_p, 2);
                 
                 
                  $(this).parent().find('.precio_total_promo').val(_p);
                } 

                _c = comprobarOpcion(_numproducto);
                
                calcularTotalesPromocion(_importe_minimo, _importe_permitido);
               // comprobarPromocion(_importe_minimo, _importe_permitido, _promo_fija);
             } 
          });

          $('.js-btn-anadir_producto').on('click', function (){

              _numProductos++;
              

              _nump = $(this).data('numproducto');
              //obj=$(this).closest(".mod-elegir_producto_promo").find('.item-original');
              obj=$('#item-original-'+_nump);

              newClone = obj.clone(true);
              //Le asigno el nuevo numero id
              newClone.attr("id",'l-producto-'+_nump+'-'+_numProductos);


              newClone.insertBefore($('#item-inactivo-'+_nump));


              $('#l-producto-'+_nump+'-'+_numProductos+" .js-producto-promo_selecion").attr('id', 'producto-'+_nump+'-'+_numProductos+'-producto');
              $('#l-producto-'+_nump+'-'+_numProductos+" .js-producto-promo_selecion").attr('name', 'producto-'+_nump+'-'+_numProductos+'-producto');
              $('#l-producto-'+_nump+'-'+_numProductos+" .js-producto-promo_selecion").addClass('js-control-relleno');

              $('#l-producto-'+_nump+'-'+_numProductos+" .js-cantidad-producto-promo").attr('id', 'producto-'+_nump+'-'+_numProductos+'-unidades');
              $('#l-producto-'+_nump+'-'+_numProductos+" .js-cantidad-producto-promo").attr('name', 'producto-'+_nump+'-'+_numProductos+'-unidades');
              $('#l-producto-'+_nump+'-'+_numProductos+" .js-precio_ud_promo").attr('id', 'producto-'+_nump+'-'+_numProductos+'-precioud');
              $('#l-producto-'+_nump+'-'+_numProductos+" .js-precio_ud_promo").attr('name', 'producto-'+_nump+'-'+_numProductos+'-precioud');
              $('#l-producto-'+_nump+'-'+_numProductos+" .js-precio_total_promo").attr('id', 'producto-'+_nump+'-'+_numProductos+'-preciototal');
              $('#l-producto-'+_nump+'-'+_numProductos+" .js-precio_total_promo").attr('name', 'producto-'+_nump+'-'+_numProductos+'-preciototal');


              $('#l-producto-'+_nump+'-'+_numProductos).show();
             // console.log('prod::'+_nump+' '+$('#producto-'+_numProductos).html());

              /*$('#producto-'+_numProductos+" .js-producto-promo_selecion").attr('id', 'producto-'+_numProductos+'-producto');
              $('#producto-'+_numProductos+" .js-producto-promo_selecion").attr('name', 'producto-'+_numProductos+'-producto');
              $('#producto-'+_numProductos+" .js-cantidad-producto-promo").attr('id', 'producto-'+_numProductos+'-unidades');
              $('#producto-'+_numProductos+" .js-cantidad-producto-promo").attr('name', 'producto-'+_numProductos+'-unidades');
              $('#producto-'+_numProductos+" .js-precio_ud_promo").attr('id', 'producto-'+_numProductos+'-precioud');
              $('#producto-'+_numProductos+" .js-precio_ud_promo").attr('name', 'producto-'+_numProductos+'-precioud');
              $('#producto-'+_numProductos+" .js-precio_total_promo").attr('id', 'producto-'+_numProductos+'-preciototal');
              $('#producto-'+_numProductos+" .js-precio_total_promo").attr('name', 'producto-'+_numProductos+'-preciototal');
              
              $('#producto-'+_numProductos).show();*/
          });

          $('.js-eliminar-item').on('click', function (){
           // $(this).parent().parent().parent().parent().remove();
           _numproducto = $(this).data('numproducto');
            $(this).closest('.item-promo').remove();
            
            _c = comprobarOpcion(_numproducto);
                
            calcularTotalesPromocion(_importe_minimo, _importe_permitido);

           // comprobarPromocion(_importe_minimo, _importe_permitido, _promo_fija);
          });

           $('#pestanya_0').addClass('show');

           /**
            * bloquea la interactuación de usuario si no está logado
            */
           $(".linea-nologado").on('click', function(e){
              var obj=$('#js-bt-comprar-promocion');
              if (obj.data('logado')){
                  if (obj.data('exclusiva-cliente') == '1' &&  obj.data('cliente-nuevo') != 'si'){
                      mostrar_msg('#myModal', 'Lo sentimos', 'Según nuestra Base de datos, tú eres un cliente de más de 1 año de antigüedad, y no puedes acceder a esta promoción. Ponte en contacto con nosotros para ofrecerte otras promociones. Gracias.');
                  }
              }else{
                var url=obj.data('pagina-login')+'?url_callback='+obj.data('pagina-redirect');
                mostrar_msg('#myModal', 'Lo sentimos!', 'Necesitamos que te registres <a href="'+url+'">aquí</a>, para saber si eres nuevo cliente, antes de comprar o configurar la promoción.');
              }
           });

           $('#js-bt-add-promocion').on('click', function(){
              if ($(this).data('logado')){
                  if ($(this).data('exclusiva-cliente') == '1' &&  $(this).data('cliente-nuevo') != 'si'){
                        mostrar_msg('#myModal', 'Lo sentimos', 'Según nuestra Base de datos, tú eres un cliente de más de 1 año de antigüedad, y no puedes acceder a esta promoción. Ponte en contacto con nosotros para ofrecerte otras promociones. Gracias.');
                  }
                  else {
                        //err = comprobarPromocion(_importe_minimo, _importe_permitido, _promo_fija);
                        err = comprobarOpcionesRellenas();
                        if (err){
                            mostrar_msg('#myModal', 'UPS!', 'Debes seleccionar todas las opciones.');
                        }  
                        else{
                          err = comprobarPromocion();
                          if (!err){
                                calcularTotalesPromocion(_importe_minimo, _importe_permitido);
                                // alert('addPromo');

                                addPromocion('');
                                $('.js-carrito').addClass("abierto");
                                $(".modal-articulos-carrito").addClass("activo");
                                  var obj=$('.js-carrito');
                                  $('.js-carrito').removeClass("abierto");
                                  intMenu=setTimeout(function(){
                                    if(!obj.hasClass("abierto")){
                                      $(".modal-articulos-carrito").removeClass("activo");
                                     }
                                  },2000);  
                               
                               // alerta_carrito('¡Genial!<br> Has añadido <strong>'+$(this).data('nombre-producto')+'</strong><br> a tu carrito de la compra ');

                          }
                        }  
                  }
              }
              else {
                //mostrar_msg('#myModal', 'Lo sentimos', 'Para acceder a la promoción tienes que estar registrado.');
                var url=$(this).data('pagina-login')+'?url_callback='+$(this).data('pagina-redirect');
                mostrar_msg('#myModal', 'Lo sentimos!', 'Necesitamos que te registres <a href="'+url+'">aquí</a>, para saber si eres nuevo cliente, antes de comprar o configurar la promoción.');
                  // document.location.href=$(this).data('pagina-login')+'?url_callback='+$(this).data('pagina-redirect');

              }
           });

           $('#js-bt-comprar-promocion').on('click', function(){
              if ($(this).data('logado')){
                  if ($(this).data('exclusiva-cliente') == '1' &&  $(this).data('cliente-nuevo') != 'si'){
                        mostrar_msg('#myModal', 'Lo sentimos', 'Según nuestra Base de datos, tú eres un cliente de más de 1 año de antigüedad, y no puedes acceder a esta promoción. Ponte en contacto con nosotros para ofrecerte otras promociones. Gracias');
                  }
                  else {
                      //err = comprobarPromocion(_importe_minimo, _importe_permitido, _promo_fija);
                      err = comprobarOpcionesRellenas();
                        if (err){
                            mostrar_msg('#myModal', 'UPS!', 'Debes seleccionar todas las opciones.');
                        }  
                        else{  

                          err = comprobarPromocion();
                            if (!err){
                                calcularTotalesPromocion();

                                // alert('addPromo');
                                 addPromocion($(this).data('href-carrito'));
                                  
                                  /*$('.js-carrito').addClass("abierto");
                                  $(".modal-articulos-carrito").addClass("activo");
                                    var obj=$('.js-carrito');
                                    $('.js-carrito').removeClass("abierto");
                                    intMenu=setTimeout(function(){
                                      if(!obj.hasClass("abierto")){
                                        $(".modal-articulos-carrito").removeClass("activo");
                                       }
                                    },2000);  */

                            }
                        }    
                  }
              }
              else {
                var url=$(this).data('pagina-login')+'?url_callback='+$(this).data('pagina-redirect');
                mostrar_msg('#myModal', 'Lo sentimos!', 'Necesitamos que te registres <a href="'+url+'">aquí</a>, para saber si eres nuevo cliente, antes de comprar o configurar la promoción');
                // document.location.href=$(this).data('pagina-login')+'?url_callback='+$(this).data('pagina-redirect');
              }
           });

            $('.btn-soporte').on('click', function(){
                 if ($('#selformato option:selected') .attr('stock') == '3'){
                  mostrar_msg("#myModal", 'Sin Stock', 'Producto agotado.')
                }
                else {
                    _idsoporte = $(this).data('soporte-id');
                    _descripcion_soporte = $(this).data('soporte-descripcion');
                    //alert(_descripcion_soporte);
                    _idproducto = $(this).data('producto-id');
                    _unidades_soporte = $('#selsoportes_'+_idsoporte).val();
                    addSoporteCarrito(_idsoporte, _descripcion_soporte,_unidades_soporte,_idproducto);
                }    
            });  

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'page_template_008_cesta_compra':{
      init: function() {
          // JavaScript to be fired on the about us page
          /*
          $('#estandar').prop('checked', true);
          $('#selpais').val(704);//España
          $('#selprovincia').val('Madrid');//España
          */
          $(".js-carrito").unbind('hover');
          $('.btn-mas').on('click', function() {
              $('.bt-compra-cesta').attr("disabled", true);
              $('.btn-mas').attr("disabled", true);
              $('.btn-menos').attr("disabled", true);
              /* Act on the event */
              _idprod = $(this).data("idproducto");
              _idformato = $(this).data("formato_idformato"); 
              item_obj = $(this).closest('.btn-cantidad').find(".boton-mas"); 

              //item_obj = $('.boton-mas[data-index='+_index+']');
              //_val = parseInt($('#item-cantidad-'+_idprod+'_'+_idformato).val());
              _val = parseInt(item_obj.val());
   
              _val++;
              //$('#item-cantidad-'+_idprod+'_'+_idformato).val(_val);
              item_obj.val(_val);
             
              _unidades = _val;
              _index = $(this).closest('.btn-cantidad').attr('data-index');

              // duplico unidades regalos de este item
             // $(".cont-regalo-productos_cesta.item-"+_idprod+"-"+_idformato+" .js-unidades-regalo-producto_cesta").each(function(){
              $(".cont-regalo-productos_cesta.item-"+_idprod+"-"+_idformato+".producto-indice-"+_index+" .js-unidades-regalo-producto_cesta").each(function(){
                var unidades_regalo=$(this).data("unidades-regalo");
                $(this).val(unidades_regalo*_val);
              });

              

              recalcularCesta(_idprod, _idformato, _unidades, _index);

             // calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#selpais').val(), $('#selprovincia').val(), false, false, false, $('#ind_facturacion').val()); 
              
              cargarCesta();
              recargarCesta(_cestaCarrito);  
              
              //recargarRegalosCesta();
          });

          $('.btn-menos').on('click', function() {
            $('.bt-compra-cesta').attr("disabled", true);
            $('.btn-mas').attr("disabled", true);
            $('.btn-menos').attr("disabled", true);
            /* Act on the event */
            _idprod = $(this).data("idproducto");
            _idformato = $(this).data("formato_idformato"); 
            item_obj = $(this).closest('.btn-cantidad').find(".boton-mas"); 
           
            //_val = parseInt(jQuery('#item-cantidad-'+_idprod+'_'+_idformato).val());
             _val = parseInt(item_obj.val());
            _val--;
            if (_val==0){
              _val=1;
            }

            
              //$('#item-cantidad-'+_idprod+'_'+_idformato).val(_val);
              item_obj.val(_val);



           
            _unidades = _val;
            _index = $(this).closest('.btn-cantidad').attr('data-index');

            // duplico unidades regalos de este item
            //$(".cont-regalo-productos_cesta.item-"+_idprod+"-"+_idformato+" .js-unidades-regalo-producto_cesta").each(function(){
            $(".cont-regalo-productos_cesta.item-"+_idprod+"-"+_idformato+".producto-indice-"+_index+" .js-unidades-regalo-producto_cesta").each(function(){
              var unidades_regalo=$(this).data("unidades-regalo");
              $(this).val(unidades_regalo*_val);
            });
            
            recalcularCesta(_idprod, _idformato, _unidades, _index);

            //calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#selpais').val(), $('#selprovincia').val(), false, false, false, $('#ind_facturacion').val()); 
            cargarCesta();
            recargarCesta(_cestaCarrito);
            //recargarRegalosCesta();
          });

          $('.btn-seguir').on('click', function(){
            document.location.href='/';
          });

          $('.btn-vaciar').on('click', function(){
            $.ajax({
              url: ajaxTheme,
              type: "POST",
              dataType: "json",
              data: '&action=vaciarCesta',
              success: function (response) {
                document.location.href='/';
              },
              error: function (xhr, status) {
              },
              complete: function (xhr, status) {
              }
            });
            
          });

          initClickEliminarProductoCesta();

          _tipo_pedido = $('input:radio[name=envio]:checked').val();
          calcularGastosEnvio(_tipo_pedido, $('#selpais').val(), $('#selprovincia').val(), false, false, false, $('#ind_facturacion').val());

          $('#selpais').on('change', function (){
            
              $.ajax({
                  url: ajaxTheme,
                  type: "POST",
                  dataType: "json",
                  data: '&action=cargarProvincia&idpais='+$(this).val(),
                  success: function (response) {
                     //console.log(response); 

                    $("#selprovincia option[value!='']").remove();
                    for (c=0;c<response.length;c++){
                      $('#selprovincia').append('<option value="'+response[c]['provincia']+'" data-iva-general="'+response[c]['iva_general']+'" data-iva-reducido="'+response[c]['iva_reducido']+'" data-iva-superreducido="'+response[c]['iva_superreducido']+'" data-zona-pais="'+response[c]['zona_pais']+'">'+response[c]['provincia']+'</option>');
                    } 
                    calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#selpais').val(), $('#selprovincia').val(), false, false, false, $('#ind_facturacion').val());  
                  },
                  error: function (xhr, status) {
                  },
                  complete: function (xhr, status) {
                  }
              });
          });
          $('#selprovincia').on('change', function (){
             calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#selpais').val(), $('#selprovincia').val(), false, false, false, $('#ind_facturacion').val());  
          });  
          $('input:radio[name=envio]').on('change', function (){
             calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#selpais').val(), $('#selprovincia').val(), false, false, false, $('#ind_facturacion').val());  
          });  
         
          $('#btn-comprar').on('click', function(){
            if ($(this).data('login')){//logado
              document.location.href = '/proceso-compra';
            }
            else {//sin logar
              document.location.href=$(this).data('pagina-login')+'?url_callback='+$(this).data('pagina-redirect');
            }

          });

          $('.js-link_producto').css('cursor', 'pointer');
          $('.js-link_producto').on('click', function(){  
              _link = $(this).data('link-producto');
              document.location.href=_link;
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    'page_template_009_proceso_compra':{
          init: function() {



            $(".js-direccionEnvio").change(function(event) {
              $(".txtdirEnvio").hide();
              $(".txtdirEnvio_"+$(this).find(":selected").val()).show();
        
              datos=JSON.parse($(this).find(":selected").attr("data-datos"));
              $("#formEnvio #empresa").val(datos.empresa);
              $("#formEnvio #establecimiento").val(datos.establecimiento);
              $("#formEnvio #nombre").val(datos.nombre);
              $("#formEnvio #apellidos").val(datos.apellidos);
              $("#formEnvio #direccion").val(datos.direccion);
              $("#formEnvio #pais").html('<option class="item-pais" value="'+datos.pais+'">'+datos.pais+'</option>');
              
              $("#formEnvio #provincia").html('<option class="item-pais" value="'+datos.provincia+'">'+datos.provincia+'</option>')
              $("#formEnvio #poblacion").val(datos.poblacion);
              $("#formEnvio #codigopostal").val(datos.cp);
              $("#formEnvio #telefono").val(datos.telefono);
        
              $("#formEnvio #provincia").trigger("change");
        
            });
        
        
            $(".js-direccionFacturacion").change(function(event) {
        
              $(".txtdirFacturacion").hide();
              $(".txtdirFacturacion_"+$(this).find(":selected").val()).show();
        
              datos=JSON.parse($(this).find(":selected").attr("data-datos"));
              
              $("#formFacturacion #empresa_fact").val(datos.empresa_fact);
              $("#formFacturacion #cif_fact").val(datos.cif);
              $("#formFacturacion #direccion_fact").val(datos.direccion);
              $("#formFacturacion #pais_fact").html('<option class="item-pais" value="'+datos.pais+'">'+datos.pais+'</option>');
              $("#formFacturacion #provincia_fact").html('<option class="item-provincia" value="'+datos.provincia+'">'+datos.provincia+'</option>');
              $("#formFacturacion #poblacion_fact").val(datos.poblacion);
              $("#formFacturacion #codigopostal_fact").val(datos.cp);
              $("#formFacturacion #telefono_fact").val(datos.telefono);
        
              if (datos.facturar_recargo_equivalencia==false){
                $("#inputcheckbox_recargo").prop("checked",null);
              }else{
                $("#inputcheckbox_recargo").attr("checked","checked");
              }
        
              $("#inputcheckbox_recargo").trigger("click");
              
              $('#provincia_fact').trigger("change");
        
            });		
        
        
        
            //nuevo tpv
            $('.js-tarjeta').click(function (e) { 
              e.preventDefault();
              // $('input[name="metodopago"]').prop('checked', false); 
              $('#storedcard').val($(this).data('rel'));
              $('.tarjetas > li').removeClass('active');
              $(this).parent().addClass('active');
              if($(this).data('rel')==0){
                $('#form-new-card').slideDown();
              }else{
                $('#form-new-card').slideUp();
              }
            });

            $('input[name="metodopago"]').click(function (e) { 
             // e.preventDefault();
              $('#storedcard').val('');
              $('.tarjetas > li').removeClass('active');
              $('#form-new-card').slideUp();

              //despliega contenido
              $('.cont-forma-pago').hide();
              $('#forma-pago-'+$(this).attr('id')).slideToggle();
            });

              //$("#visa").prop("checked", true);
              $(".ck_descontar_saldo").prop("checked", false);
              
              $('#inputcheckbox_diferente').on('change', function(){
                //$('#div_check-diferente').hide();
                if ($(this).prop('checked')){
                  $('.class_ocultar').show();
                }
                else {
                  $('.class_ocultar').hide();
                  /*$('#direccion_fact').val($('#direccion').val());   
                  $('#pais_fact').val($('#pais').val());  
                  $('#provincia_fact').val($('#provincia').val());  
                  $('#poblacion_fact').val($('#poblacion').val());  
                  $('#codigopostal_fact').val($('#codigopostal').val());  
                  $('#telefono_fact').val($('#telefono').val()); */
                }
              });

              $("#formEnvio .js-pais").change(function(event) {
                $("#formFacturacion .js-pais").val($(this).val());
                $("#formFacturacion .js-pais").trigger('change');
                $("#formEnvio .js-provincia").html("");
                $("#formEnvio .js-provincia").append('<option class="item-provincia" value="">Elige provincia</option>');
                if ($(this).find(":selected").attr("data-provincias")!="" && $(this).find(":selected").attr("data-provincias")!="undefined" && $(this).find(":selected").attr("data-provincias")!=undefined){

                  provinciasPais=JSON.parse($(this).find(":selected").attr("data-provincias"));
                  jQuery.each(provinciasPais, function(i, val) {
                  $("#formEnvio .js-provincia").append('<option class="item-provincia" value="'+val+'">'+val+'</option>');
                });

                }


                _recoger = false;
                if( $('#recogida').prop('checked') ) {
                  _recoger = true;
                }  
                
                _contrareembolso = false;
                if( $('input:radio[name=metodopago]:checked').val() == 'contrareembolso' ) {
                  _contrareembolso = true;
                 }  
                _recargoequivalencia = false;
                if( $('#inputcheckbox_recargo').prop('checked') ) {
                  _recargoequivalencia = true;
                }

                if ($('#direcionFacturacion').is(":visible")){
                  _indFact = parseInt($('#direcionFacturacion').prop('selectedIndex'))+1;
                }
                else{
                  _indFact = -1;
                }

              
                
                _alerta_descuento = false;
                if ($('#puntos_importe').val() > 0 || $('#abonos').val() > 0){
                  _alerta_descuento = true;
                }

                calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $(this).val(), '', _recoger, _contrareembolso, _recargoequivalencia, _indFact);
                    
                if (_alerta_descuento){
                  mostrar_msg('#myModal', 'AVISO', 'Has hecho un cambio en tu configuración y hemos reseteado tus descuentos. Por favor, vuelve a descontarlos.');
                }  


              });

              $("#formFacturacion .js-pais").change(function(event) {
                $("#formFacturacion .js-provincia").html("");
                $("#formFacturacion .js-provincia").append('<option class="item-provincia" value="">Elige provincia</option>');

                if ($(this).find(":selected").attr("data-provincias")!="" && $(this).find(":selected").attr("data-provincias")!="undefined" && $(this).find(":selected").attr("data-provincias")!=undefined){

                  provinciasPais=JSON.parse($(this).find(":selected").attr("data-provincias"));
                  jQuery.each(provinciasPais, function(i, val) {
                  $("#formFacturacion .js-provincia").append('<option class="item-provincia" value="'+val+'">'+val+'</option>');
                });

                }


              });

              $('#provincia').on('change', function(event){
                $('#provincia_fact').val($(this).val());
                event.preventDefault();
                _recoger = false;
                if( $('#recogida').prop('checked') ) {
                  _recoger = true;
                }  
                _contrareembolso = false;
                if( $('input:radio[name=metodopago]:checked').val() == 'contrareembolso'  ) {
                  _contrareembolso = true;
                }  
                _recargoequivalencia = false;
                if( $('#inputcheckbox_recargo').prop('checked') ) {
                  _recargoequivalencia = true;
                }

                if ($('#direcionFacturacion').is(":visible")){
                  _indFact = parseInt($('#direcionFacturacion').prop('selectedIndex'))+1;
                }
                else{
                  _indFact = -1;
                }

                
                _alerta_descuento = false;
                if ($('#puntos_importe').val() > 0 || $('#abonos').val() > 0){
                  _alerta_descuento = true;
                }

                calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#pais').val(), $(this).val(), _recoger, _contrareembolso, _recargoequivalencia, _indFact);
                    
                if (_alerta_descuento){
                  mostrar_msg('#myModal', 'AVISO', 'Has hecho un cambio en tu configuración y hemos reseteado tus descuentos. Por favor, vuelve a descontarlos.');
                }  



              });

             $('#provincia_fact').on('change', function(){
                _recargoequivalencia = false;
                if( $('#inputcheckbox_recargo').prop('checked') ) {
                  _recargoequivalencia = true;
                }

                if ($('#direcionFacturacion').is(":visible")){
                  _indFact = parseInt($('#direcionFacturacion').prop('selectedIndex'))+1;
                }
                else{
                  _indFact = -1;
                }

                _recoger = false;
                if( $('#recogida').prop('checked') ) {
                  _recoger = true;
                }  
                _contrareembolso = false;
                if( $('input:radio[name=metodopago]:checked').val() == 'contrareembolso'  ) {
                  _contrareembolso = true;
                } 

                _alerta_descuento = false;
                if ($('#puntos_importe').val() > 0 || $('#abonos').val() > 0){
                  _alerta_descuento = true;
                }

                //calcularTotalPedido(_recargoequivalencia, _indFact); 
                
                calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#pais').val(), $(this).val(), _recoger, _contrareembolso, _recargoequivalencia, _indFact);
    
                if (_alerta_descuento){
                  mostrar_msg('#myModal', 'AVISO', 'Has hecho un cambio en tu configuración y hemos reseteado tus descuentos. Por favor, vuelve a descontarlos.');
                }  

             });   

             $($('input:radio[name=metodopago]')).on('change', function(event){
                event.preventDefault();
                 _recoger = false;
                if( $('#recogida').prop('checked') ) {
                  _recoger = true;
                }  
                _contrareembolso = false;
                if( $('input:radio[name=metodopago]:checked').val() == 'contrareembolso'  ) {
                  _contrareembolso = true;
                } 
                _recargoequivalencia = false; 
                if( $('#inputcheckbox_recargo').prop('checked') ) {
                  _recargoequivalencia = true;
                }

                if ($('#direcionFacturacion').is(":visible")){
                  _indFact = parseInt($('#direcionFacturacion').prop('selectedIndex'))+1;
                }
                else{
                  _indFact = -1;
                }

                _alerta_descuento = false;
                if ($('#puntos_importe').val() > 0 || $('#abonos').val() > 0){
                  _alerta_descuento = true;
                }

                calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#pais').val(), $('#provincia').val(), _recoger, _contrareembolso, _recargoequivalencia, _indFact);
                    
                if (_alerta_descuento){
                  mostrar_msg('#myModal', 'AVISO', 'Has hecho un cambio en tu configuración y hemos reseteado tus descuentos. Por favor, vuelve a descontarlos.');
                }  



              });

              $('#recogida').on('change', function(event){
                event.preventDefault();
                 _recoger = false;
                if( $(this).prop('checked') ) {
                  _recoger = true;
                }  
                _contrareembolso = false;
                if( $('input:radio[name=metodopago]:checked').val() == 'contrareembolso'  ) {
                  _contrareembolso = true;
                }  
                _recargoequivalencia = false;
                if( $('#inputcheckbox_recargo').prop('checked') ) {
                  _recargoequivalencia = true;
                }

                if ($('#direcionFacturacion').is(":visible")){
                  _indFact = parseInt($('#direcionFacturacion').prop('selectedIndex'))+1;
                }
                else{
                  _indFact = -1;
                }
                
                _alerta_descuento = false;
                if ($('#puntos_importe').val() > 0 || $('#abonos').val() > 0){
                  _alerta_descuento = true;
                }

                calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#pais').val(), $('#provincia').val(), _recoger, _contrareembolso, _recargoequivalencia, _indFact);
                    
                if (_alerta_descuento){
                  mostrar_msg('#myModal', 'AVISO', 'Has hecho un cambio en tu configuración y hemos reseteado tus descuentos. Por favor, vuelve a descontarlos.');
                }  

              });
              
              $('.tipoEnvio').on('change', function(event){
                event.preventDefault();
                 _recoger = false;
                if( $('#recogida').prop('checked') ) {
                  _recoger = true;
                }  
                _contrareembolso = false;
                if( $('input:radio[name=metodopago]:checked').val() == 'contrareembolso'  ) {
                  _contrareembolso = true;
                }  
                _recargoequivalencia = false;
                if( $('#inputcheckbox_recargo').prop('checked') ) {
                  _recargoequivalencia = true;
                }

                if ($('#direcionFacturacion').is(":visible")){
                  _indFact = parseInt($('#direcionFacturacion').prop('selectedIndex'))+1;
                }
                else{
                  _indFact = -1;
                }

                _alerta_descuento = false;
                if ($('#puntos_importe').val() > 0 || $('#abonos').val() > 0){
                  _alerta_descuento = true;
                }
                
                calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#pais').val(), $('#provincia').val(), _recoger, _contrareembolso, _recargoequivalencia, _indFact);

               /* if (_contrareembolso){
                  console.log('contrareembolso');
                      $.ajax({
                              url: ajaxTheme,
                              type: "POST",
                              dataType: "json",
                              //data: "&action=descontarPuntos&importe_descontar="+$('#euros_descontar').val()+"&puntos="+$('#puntos_descontar').html(),
                              data: "&action=cambiarTotalParaContrareembolso",
                              success: function (response) {
                                 //console.log(response);
                                 console.log('va a calcular');
                                  calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#pais').val(), $('#provincia').val(), _recoger, _contrareembolso, _recargoequivalencia, _indFact);
                                      
                                  if (_alerta_descuento){
                                    mostrar_msg('#myModal', 'AVISO', 'Has hecho un cambio en tu configuración y hemos reseteado tus descuentos. Por favor, vuelve a descontarlos.');
                                  } 
                              },
                              error: function (xhr, status) {
                              },
                              complete: function (xhr, status) {
                                   
                              }
                      });
                 
                  }
                  else{
                    console.log('pasa1');
                      calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#pais').val(), $('#provincia').val(), _recoger, _contrareembolso, _recargoequivalencia, _indFact);
                                      
                      if (_alerta_descuento){
                        mostrar_msg('#myModal', 'AVISO', 'Has hecho un cambio en tu configuración y hemos reseteado tus descuentos. Por favor, vuelve a descontarlos.');
                      } 
                  }    
                  */
                            

              });

              $('#inputcheckbox_recargo').on('change', function(event){
                event.preventDefault();
                _recoger = false;
                if( $('#recogida').prop('checked') ) {
                  _recoger = true;
                }  
                _contrareembolso = false;
                if( $('input:radio[name=metodopago]:checked').val() == 'contrareembolso'  ) {
                  _contrareembolso = true;
                } 
                _recargoequivalencia = false;
                if( $(this).prop('checked') ) {
                  _recargoequivalencia = true;
                } 

                if ($('#direcionFacturacion').is(":visible")){
                  _indFact = parseInt($('#direcionFacturacion').prop('selectedIndex'))+1;
                }
                else{
                  _indFact = -1;
                }

                _alerta_descuento = false;
                if ($('#puntos_importe').val() > 0 || $('#abonos').val() > 0){
                  _alerta_descuento = true;
                }

                calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#pais').val(), $('#provincia').val(), _recoger, _contrareembolso, _recargoequivalencia, _indFact);
                    
                if (_alerta_descuento){
                  mostrar_msg('#myModal', 'AVISO', 'Has hecho un cambio en tu configuración y hemos reseteado tus descuentos. Por favor, vuelve a descontarlos.');
                }    
               
              });


              

              $('#btn-comprar').on('click', function(event){
                  event.preventDefault();
                  //loader_spinner("js-spinner-compra","show");
                  //$('#js-spinner-compra').spin('show');

                  //$('#js-spinner-compra').spin('show');

                  //$('.spin').spin('show');
                  mostrarSpin();

                  $('#btn-comprar').attr("disabled", true);
                  $("#formEnvio .alert").hide();
                  $("#formFacturacion .alert").hide();
                  $("#formTipoEnvio .alert").hide();
                  $("#formMetodoPago .alert").hide();

                  if ( $('#tipo_cliente').val() == 'particular'){
                    if ($('#inputcheckbox_diferente').length && !$('#inputcheckbox_diferente').prop('checked') ) {
                       $('#direccion_fact').val($('#direccion').val());   
                       $('#pais_fact').val($('#pais').val());  
                       $('#provincia_fact').val($('#provincia').val());  
                       $('#poblacion_fact').val($('#poblacion').val());  
                       $('#codigopostal_fact').val($('#codigopostal').val());  
                       $('#telefono_fact').val($('#telefono').val());  
                       
                    }  
                    $('#nombre_fact').val($('#nombre').val());  
                    $('#apellidos_fact').val($('#apellidos').val()); 
                  }  
                  
                  error = false;
                  errorEnvio = validarEnvio();
                  if (errorEnvio){
                    error = true;
                    $('#textoEnvio').hide();
                    $('#formEnvio').show();
                    alerta($("#formEnvio .alert"),"alert-danger","Comprueba que has rellenado correctamente los campos obligatorios",false);
                    $('#btn-comprar').attr("disabled", false);
                    ocultarSpin();
                  }

                  if (!error){
                    errorFacturacion = validarFacturacion();
                    if (errorFacturacion){
                      error = true;
                      $('#textoFacturacion').hide();
                      $('#formFacturacion').show();
                      alerta($("#formFacturacion .alert"),"alert-danger","Comprueba que has rellenado correctamente los campos obligatorios",false);
                      $('#btn-comprar').attr("disabled", false);
                      ocultarSpin();
                    }
                  }


                  if (!error){  
                    errorOtros = validarTipoEnvio();
                    if (errorOtros){
                        error = true;
                        alerta($("#formTipoEnvio .alert"),"alert-danger","Debes indicar el tipo de envío",false);
                        $('#btn-comprar').attr("disabled", false);
                        ocultarSpin();
                    }
                  }
                  
                  if (!error){
                    errorOtros = validarMetodoPago();
                    if (errorOtros){
                        error = true;
                        alerta($("#formMetodoPago .alert"),"alert-danger","Comprueba que has rellenado correctamente los datos del método de pago.",false);
                        $('#btn-comprar').attr("disabled", false);
                        ocultarSpin();
                    }
                  }                  


                  //compruebo que el dni de facturación no exista ya solo en caso de que se esté mostrando el formulario de facturación
                  //porque en ese caso se habría introducido un nuevo DNI
                  if (!error && jQuery("#formFacturacion").is(":visible")){


                    $.ajax({
                      method: "POST",
                      url: ajaxTheme,
                      data: { action: "validarDNIRepetido", dni: jQuery("#cif_fact").val() },
                      dataType: "json",
                      success: function (response) {
                        
                          if (response['resultado'] == 'OK'){
                                 
                            if (!error){
                                
                                if (!$('#ckpolitica').prop('checked') ) {
                                  alerta($("#formLegales .alert"),"alert-danger","Debes aceptar las condiciones de compra y la política de privacidad.",false);
                                  $('#btn-comprar').attr("disabled", false);
                                  ocultarSpin();
                                }  
                                else {
                                    _recoger = false;
                                    if( $('#recogida').prop('checked') ) {
                                      _recoger = true;
                                    }  
                                    _contrareembolso = false;
                                    if( $('input:radio[name=metodopago]:checked').val() == 'contrareembolso'  ) {
                                      _contrareembolso = true;
                                    } 
                                    _recargoequivalencia = false;
                                    if( $('#inputcheckbox_recargo').prop('checked') ) {
                                      _recargoequivalencia = true;
                                    } 
                                    
                                    /*if(modo_produccion==0){
                                      guardarPedido($('input:radio[name=envio]:checked').val(), $('#pais').val(), $('#provincia').val(), _recoger, _contrareembolso, _recargoequivalencia);  
                                    }else{
                                      guardarPedidoProduccion($('input:radio[name=envio]:checked').val(), $('#pais').val(), $('#provincia').val(), _recoger, _contrareembolso, _recargoequivalencia);  
                                    }*/
                                    guardarPedidoProduccion($('input:radio[name=envio]:checked').val(), $('#pais').val(), $('#provincia').val(), _recoger, _contrareembolso, _recargoequivalencia);    
                                }    
                            }else {
                              //loader_spinner("js-spinner-compra","hide");
                              //$('.spin').spin('hide');
                              ocultarSpin();
                            }                                 
                             
                          }
                          else{
                              mostrar_msg('#myModal', 'Error', response['msg']);
                              ocultarSpin();
                              $('#btn-comprar').attr("disabled", false);
                          }

                      },
                      error: function (xhr, status) {
                         

                      },
                      complete: function (xhr, status) {
                           
                      }                      
                    });


                  }else{

                    if (!error){
                        
                        if (!$('#ckpolitica').prop('checked') ) {
                          alerta($("#formLegales .alert"),"alert-danger","Debes aceptar las condiciones de compra y la política de privacidad.",false);
                          $('#btn-comprar').attr("disabled", false);
                          ocultarSpin();
                        }  
                        else {
                            _recoger = false;
                            if( $('#recogida').prop('checked') ) {
                              _recoger = true;
                            }  
                            _contrareembolso = false;
                            if( $('input:radio[name=metodopago]:checked').val() == 'contrareembolso'  ) {
                              _contrareembolso = true;
                            } 
                            _recargoequivalencia = false;
                            if( $('#inputcheckbox_recargo').prop('checked') ) {
                              _recargoequivalencia = true;
                            } 
                            
                            /*if(modo_produccion==0){
                              guardarPedido($('input:radio[name=envio]:checked').val(), $('#pais').val(), $('#provincia').val(), _recoger, _contrareembolso, _recargoequivalencia);  
                            }else{
                              guardarPedidoProduccion($('input:radio[name=envio]:checked').val(), $('#pais').val(), $('#provincia').val(), _recoger, _contrareembolso, _recargoequivalencia);  
                            }*/
                            guardarPedidoProduccion($('input:radio[name=envio]:checked').val(), $('#pais').val(), $('#provincia').val(), _recoger, _contrareembolso, _recargoequivalencia);    
                        }    
                    }else {
                      //loader_spinner("js-spinner-compra","hide");
                      //$('.spin').spin('hide');
                      ocultarSpin();
                    }

                  }

              });

              $('.js-add_direccion_envio').on('click', function(event){
                  event.preventDefault;
                  _prov = $('#provincia').data('provincia');
                  $('#provincia').val(_prov);
                  $('#textoEnvio').fadeOut();
                  $('#formEnvio').fadeIn();
                  
              });
              $('.js-add_direccion_facturacion').on('click', function(event){
                  event.preventDefault;
                  _prov = $('#provincia_fact').data('provincia');
                  $('#provincia_fact').val(_prov);
                  $('#textoFacturacion').fadeOut();
                  $('#formFacturacion').fadeIn();
              });


              $('#ex1').slider({});
              
              $('#ex1').on('slideStop', function(){
                $("#formPuntos .alert").hide();
                _val = $(this).val();
                _euros_por_puntos = $(this).data('euros_por_punto');
                //$('#puntos_descontar').html(_val);
                $('#puntos_descontar').val(_val);


                _descontar = Number(_val) * Number(_euros_por_puntos);
                $('#euros_descontar').val(_descontar);
                $('#importe_puntos_descontar').html(formatearImprimir(_descontar, 2));
                //console.log('a descontar::: '+_val+' euros por puntos ::'+_euros_por_puntos);
                //console.log('total:: '+$('#total').val()+' :: '+Number($('#total').val()));  
                //console.log('abonos:: '+$('#abonos').val()+' :: '+Number($('#abonos').val())); 
                _tot = pasarJS($('#total').val());

                _total = Number(_tot) -  Number($('#abonos').val());


                if ((_total - _descontar) >= 0) {
                    descontarPuntos();
                } 
                else{
                    var minval = $(this).data('slider').min; //store slider min value
                    $(this).slider('setValue', minval); //set value of slider
                    anularPuntos();
                    
                    alerta($("#formPuntos .alert"),"alert-danger","No se han podido descontar los puntos. Has llegado al máximo descuento aplicable.",false);
                }    
              });

              $('.ck_descontar_saldo').on('change', function(){
                  $("#formAbonos .alert").hide();
                  _val = $(this).data('importe-descontar');
                  _num = $(this).data('ind-abono');  
                  if ($(this).prop('checked')){
                      _tot = pasarJS($('#total').val());
                      _total = Number(_tot) -  Number($('#abonos').val()) - Number($('#puntos_importe').val());
                      //console.log('total:: '+$('#total').val()+' abonos:: '+$('#abonos').val()+' puntos:: '+$('#puntos_importe').val());
                      /*console.log('total:::'+_total);
                      console.log('val:: '+_val);*/
                      if ((_total - Number(_val)) >= 0) {
                          descontarAbono(_val, _num);
                          $('#bt-descontar-'+_num).parent().addClass('seleccionado'); 
                      } 
                      else{
                        //$('#bt-descontar-'+_num).removeAttr('checked');
                        $('#bt-descontar-'+_num).prop('checked', false);
                        $('#bt-descontar-'+_num).parent().removeClass('seleccionado');
                        alerta($("#formAbonos .alert"),"alert-danger","No se ha podido descontar el abono. Has llegado al máximo descuento.",false);
                      }
                  }
                  else {
                      $('#bt-descontar-'+_num).parent().removeClass('seleccionado');
                      anularAbono(_val, _num);
                  }    
                     
              });

              /*
              recalcular los gastos de envío antes de cargar la página para ajustar datos. Por ejemplo, si se loga cuando ya 
              tiene la compra cerrada y tiene recargo de equivalencia. O si los datos de envío (país y provincia) seleccionados 
              no coinciden con los del usuario. En este caso, hemos llegado al acuerdo de poner los que vienen en los datos del usuario
              y si quiere cambiarlo ahí el cliente, que lo haga
              */
              _recoger = false;
              if( $('#recogida').prop('checked') ) {
                _recoger = true;
              }  
                
              _contrareembolso = false;
              if( $('input:radio[name=metodopago]:checked').val() == 'contrareembolso' ) {
                _contrareembolso = true;
              }  
              _recargoequivalencia = false;
              if( $('#inputcheckbox_recargo').prop('checked') ) {
                _recargoequivalencia = true;
              }
              //console.log($('#pais').val()+' :: '+$('#provincia').val());
              if ($('#direcionFacturacion').is(":visible")){
                  _indFact = parseInt($('#direcionFacturacion').prop('selectedIndex'))+1;
                }
                else{
                  _indFact = -1;
                }
              calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#pais').val(), $('#provincia').val(), _recoger, _contrareembolso, _recargoequivalencia, _indFact);




          },
          finalize: function() {
            // JavaScript to be fired on all pages, after page specific JS is fired
          }
        },
    'page_template_012_contacta': {
      init: function() {
        // JavaScript to be fired on the about us page
        $("#divContacta .alert").hide();

        /*$('#bt-enviar').on('click', function(event) {
            $("#divContacta .alert").hide();
            $(this).prop('disabled', true);
            _error = validarContacta();
            if (!_error){
                $.ajax({
                  url: ajaxTheme,
                  type: "POST",
                  dataType: "json",
                  data: $('#formContacta').serialize(),
                  success: function (response) {
                     //console.log(response); 
                     if (response=='OK'){
                        mostrar_msg('#myModal', 'MENSAJE ENVIADO', 'Su mensaje se ha enviado correctamente, en breve nos pondremos en contacto con usted.');
                     }
                     else{
                        mostrar_msg('#myModal', 'ERROR', 'Ocurrió un error en el envío.');
                     }
                     $('#formContacta')[0].reset();
                  },
                  error: function (xhr, status) {
                  },
                  complete: function (xhr, status) {
                  }
              });
                $(this).prop('disabled', false);
            }
            else {
              alerta($("#divContacta .alert"),"alert-danger","Comprueba que has rellenado correctamente los campos obligatorios y aceptado la política de privacidad.",false);
              $(this).prop('disabled', false);
            }
        })*/
      },
      finalize: function() {
            // JavaScript to be fired on all pages, after page specific JS is fired
      }
    }, 
    'page_template_021_opinion': {
      init: function() {
        // JavaScript to be fired on the about us page
        $("#divContacta .alert").hide();
        $('#bt-enviar').on('click', function(event) {
            $("#divContacta .alert").hide();
            _error = validarContacta();
            $('#bt-enviar').prop('disabled', true);
           if (!_error){
                $.ajax({
                  url: ajaxTheme,
                  type: "POST",
                  dataType: "json",
                  data: $('#formContacta').serialize(),
                  success: function (response) {
                     //console.log(response); 
                     if (response=='OK'){
                        mostrar_msg('#myModal', 'MENSAJE ENVIADO', 'Su mensaje se ha enviado correctamente, en breve nos pondremos en contacto con usted.');
                     }
                     else{
                        mostrar_msg('#myModal', 'ERROR', 'Ocurrió un error en el envío.');
                     }
                     $('#formContacta')[0].reset();
                  },
                  error: function (xhr, status) {
                  },
                  complete: function (xhr, status) {
                  }
              });
               $('#bt-enviar').prop('disabled', false);
            }
            else {
              alerta($("#divContacta .alert"),"alert-danger","Comprueba que has rellenado correctamente los campos obligatorios y aceptado la política de privacidad.",false);
              $('#bt-enviar').prop('disabled', false);
            }
        })
      },
      finalize: function() {
            // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },       
    'page_template_016_gracias_compra': {
      init: function() {
        // JavaScript to be fired on the about us page
//        $(".js-carrito").unbind('hover');
        
        cargarCesta();
        $('#unidades_carrito').html('0');
        $('#unidades_carrito_mobile').html('0');
      },
      finalize: function() {
            // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        //console.log(">>>>>"+classnm);
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };


   cargar_items = function(){


          var _numopciones_sabores = phpVars.numopciones_sabores;
          var _idformato = phpVars.idformato;
         
         // if(anchoVentana<=767){
         //    $(".jqzoom").click(function(e){
         //      e.preventDefault();
         //      mostrar_msg("#myModal", $(".titular-ficha").html(),'<img src="'+$(this).find("img").attr("src")+'" alt="'+$(".titular-ficha").html()+'" class="img-fluid" />');

         //    });
         // }else{

         // }

            jQuery('.jqzoom').jqzoom({
              zoomType: 'standard',
              lens:true,
              preloadImages: true,
              alwaysOn:false,
              zoomWidth: 500,  
              zoomHeight:500,
              position:'left',
              preloadText: 'Cargando zoom'
            });
          

          if ($('.galeria-productos').length > 0){


            var mySwiper = new Swiper ('.galeria-productos', {
                    // Optional parameters
                    direction: 'horizontal',
                    // loop: true,
                    
                    autoplay: 3000,
                    nextButton: '.js-btnNext-producto',
                    prevButton: '.js-btnPrev-producto',
                    
                   
                    spaceBetween: 10,
                    //centeredSlides: true,
                    slidesPerView: 5,
                    breakpoints: {
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 20
                        },
                        990: {
                            slidesPerView: 3,
                            spaceBetween: 15
                        },
                        767: {
                            slidesPerView: 4,
                            spaceBetween: 15
                        },
                        576: {
                            slidesPerView: 3,
                            spaceBetween: 10
                        },
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 5
                        }
                    }                    ,
                    on: {
                    init: function () {
                      /* do something */
                      $(".producto-primero").trigger("click");
                    },

                  /*  direction: 'horizontal',
                    loop: true,
                        
                       // autoplay: 5000,
                    nextButton: '.swiper-button-next',
                    prevButton: '.swiper-button-prev',
                        
                       
                    spaceBetween: 0,
                    centeredSlides: false,
                    slidesPerView: 5,*/
                  }  
                   
             }); 
            $(".swiper-slide.producto-primero").trigger("click");
            
          }

         
          

          if ($('.swiper-container-articulos_relacionados').length > 0){
            var swiper = new Swiper('.swiper-container-articulos_relacionados', {
                nextButton: '.js-btnNext-relacionados',
                prevButton: '.js-btnPrev-relacionados',
                slidesPerView: 3,
                paginationClickable: true,
                spaceBetween: 10,
                loop: true,
                //autoplayStopOnLast: true
                    breakpoints: {
                        990: {
                            slidesPerView: 2,
                            spaceBetween: 15
                        },
                        576: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                    }
            });
          }
          if ($('.swiper-formatos').length > 0){
            var mySwiper = new Swiper ('.swiper-formatos', {
                      // Optional parameters
              direction: 'horizontal',
                loop: true,
                      
                autoplay: 2000,
                parallax: true,

            });  
          }
          
          if ($('.swiper-regalos').length > 0){  
            var mySwiper = new Swiper ('.swiper-regalos', {
                      // Optional parameters
              direction: 'horizontal',
                loop: true,
                      
                autoplay: 2500,
                parallax: false,

            });  
           }
            
        $('#item-cantidad').val('1');  
        initOpciones();
        initFormatos(_numopciones_sabores, _idformato);
        if ($('.sabores_'+_idformato).html() == '' || $('.sabores_'+_idformato).html() == 'undefined' || $('.sabores_'+_idformato).html() == undefined){
                $('#div-sabores').hide();
        } 
        
        $('#selformato').on('change', function(event) {
          /* Act on the event */


          if ($('option:selected', this).attr('data-mostrarstock')==1){
            $(".ficha-item-stock").show();
          }else{
            $(".ficha-item-stock").hide();
          }

          $('#stock-formato').removeClass( );
          _stock = $('option:selected', this).attr('stock');
          _txt = '';
          _clase = '';
          switch (_stock){
            case '0':
              _txt = '<b>Disponibilidad alta:</b> Más de '+phpVars.limite_media+' uds. en stock';
              _clase = 'disponibilidad-alta';
              break;
            case '1':
              _txt = '<b>Disponibilidad media:</b> Menos de '+phpVars.limite_media+' uds. en stock';
              _clase = 'disponibilidad-media';
              break;
            case '2': 
              _txt = '<b>Disponibilidad baja:</b> Menos de '+phpVars.limite_baja+' uds. en stock';
              _clase = 'disponibilidad-baja';
              break;
            case '3':
              _txt = '<b>Agotado:</b> Agotado';
              _clase = 'disponibilidad-agotado';
              break;
            default:
              _txt = '';
              break;      
          }
          $('#stock-formato').addClass(_clase);
          $('#stock-formato').html(_txt);

          $('.bloque-pastillas').hide();
          $('.ficha-precios_y_descuentos').hide();

          _idformatoElegido = $(this).val();
          $('#pastillas-'+_idformatoElegido).show();
          $('#datos-formato-'+_idformatoElegido).show();



          
            if (_numopciones_sabores > 0){
              $('.rows-configuracion-sabores').show();
              $('.opciones_sabores').hide();

              $('.sabores_'+_idformatoElegido).show();
              
              if ($('.sabores_'+_idformatoElegido).html() == '' || $('.sabores_'+_idformatoElegido).html() == 'undefined' || $('.sabores_'+_idformatoElegido).html() == undefined){
                $('#div-sabores').hide();
              }  
              else{
                $('#div-sabores').show();
              }
            }
            else{
              $('#div-sabores').hide();
            }
          
          //cambiar ofertas
          $('.formatos-ofertas').hide();
          $('#oferta_formato_'+_idformatoElegido).show();

          //cambiar input cesta
          _oferta = 'oferta_formato_'+_idformatoElegido;

          $('#formato_idformato-cesta').val($('#'+_oferta).data('idformato'));
          $('#formato_precio-cesta').val($('#'+_oferta).data('precio-formato'));
          $('#formato_precio-sin-iva-cesta').val($('#'+_oferta).data('precio-sin-iva-formato'));
          $('#formato_tipo_oferta-cesta').val($('#'+_oferta).data('tipo-oferta'));
          $('#formato_referencia-cesta').val($('#'+_oferta).data('referencia'));
          $('#formato_imagen_url-cesta').val($('#'+_oferta).data('imagen-url'));
          $('#formato_imagen_alt-cesta').val($('#'+_oferta).data('imagen-alt'));
          $('#formato_oferta-cesta').val($('#'+_oferta).data('oferta'));
          $('#formato_precio1-cesta').val($('#'+_oferta).data('precio1'));
          $('#formato_precio1-sin-iva-cesta').val($('#'+_oferta).data('precio1-sin-iva'));
          $('#formato_porcentaje2-cesta').val($('#'+_oferta).data('porcentaje2'));
          $('#formato_precio2-cesta').val($('#'+_oferta).data('precio2'));
          $('#formato_precio2-sin-iva-cesta').val($('#'+_oferta).data('precio2-sin-iva'));
          $('#formato_unidades3-cesta').val($('#'+_oferta).data('unidades3'));
          $('#formato_porcentaje3-cesta').val($('#'+_oferta).data('porcentaje3'));
          $('#formato_unidades4-cesta').val($('#'+_oferta).data('unidades4'));
          $('#formato_precio4-cesta').val($('#'+_oferta).data('precio4'));
          $('#formato_precio4-sin-iva-cesta').val($('#'+_oferta).data('precio4-sin-iva'));
          $('#formato_unidades5-cesta').val($('#'+_oferta).data('unidades5'));
          $('#formato_precio5-cesta').val($('#'+_oferta).data('precio5'));
          $('#formato_precio5-sin-iva-cesta').val($('#'+_oferta).data('precio5-sin-iva'));  
          $('#formato_unidades-cesta').val($('#item-cantidad').val());
          $('#formato_nombre-cesta').val($('#'+_oferta).data('texto-formato'));
          $('#formato_peso-cesta').val($('#'+_oferta).data('peso-formato'));
          $('#formato_peso_urgente-cesta').val($('#'+_oferta).data('peso-urgente-formato'));
          $('#formato_tiene_seguro-cesta').val($('#'+_oferta).data('tiene-seguro-formato'));

          _val = parseInt($('#item-cantidad').val());
          _puntos = $('option:selected', this).attr('data-puntos-asignados-formato');
          _p = _val*_puntos;
          _p = formatearImprimir(_p, 2);

          $('#puntos_acumulados').html(_p);
//alert($('option:selected', this).attr('data-puntos-asignados-formato'));
        });//fin change formato

        $('#btn-plus').on('click', function() {
          /* Act on the event */
          _val = parseInt($('#item-cantidad').val());
          _val++;
          $('#item-cantidad').val(_val);
          $('#formato_unidades-cesta').val(_val);
         // _puntos = $(this).data('puntos');
         _puntos = $('option:selected', '#selformato').attr('data-puntos-asignados-formato');
         // console.log(_puntos);
          //_puntos = pasarJS(_puntos);
          _p = _val*_puntos;
          _p = formatearImprimir(_p, 2);

          $('#puntos_acumulados').html(_p);
        });

        $('#btn-minus').on('click', function() {
          /* Act on the event */
          _val = parseInt(jQuery('#item-cantidad').val());
          _val--;
          if (_val==0){
            _val=1;
          }
          $('#item-cantidad').val(_val);
          $('#formato_unidades-cesta').val(_val);
          //_puntos = $(this).data('puntos');
          _puntos = $('option:selected', '#selformato').attr('data-puntos-asignados-formato');
          _p = _val*_puntos;
          _p = formatearImprimir(_p, 2);
          $('#puntos_acumulados').html(_p);
        });



          $('#pestanya_0').addClass('show');
          
          /*$('#btn-pedido').on('change', function(){
            addSoporte();
          });
         */
          $('.btn-carrito').on('click', function(){
            
              /* Mostraba alerta informando de lo que se había comprado, ahora tiene que aparecer el carrito. Lo dejo por si necesitamos cambiarlo de nuevo
              _error = validarFormato();
              if (!_error){
                    addCarrito($(this).attr('id'));
                      _u = $('#item-cantidad').val();
                      if (_u != '1'){
                        _unid = _u+' unidades';
                      }
                      else {
                        _unid = _u+' unidad ';
                      }
                      alerta_carrito('¡Genial!<br> Has añadido <strong>'+_unid+' de '+$('#formato_nombre-cesta').val()+'</strong><br> a tu carrito de la compra ');
                  }*/
              _error = validarFormato();
              if (!_error){
                addCarrito($(this).attr('id'), '');
                if(anchoVentana>767){
                  $('.js-carrito').addClass("abierto");
                  $(".modal-articulos-carrito").addClass("activo");
                  var obj=$('.js-carrito');
                  $('.js-carrito').removeClass("abierto");
                  intMenu=setTimeout(function(){
                    if(!obj.hasClass("abierto")){
                      $(".modal-articulos-carrito").removeClass("activo");
                     }
                  },2000);
                }
              }      
          });
          
          $('.btn-comprar').on('click', function(){
             
              _error = validarFormato();
              if (!_error){
                addCarrito($(this).attr('id'), $(this).data('href-carrito') );
                  /*          $('.js-carrito').addClass("abierto");
                            $(".modal-articulos-carrito").addClass("activo");
                              var obj=$('.js-carrito');
                              $('.js-carrito').removeClass("abierto");
                              intMenu=setTimeout(function(){
                                if(!obj.hasClass("abierto")){
                                  $(".modal-articulos-carrito").removeClass("activo");
                                 }
                              },2000);
                  */
                 
                        
              }  
          });



               




          $('.btn-soporte').on('click', function(){
             if ($('#selformato option:selected') .attr('stock') == '3'){
              mostrar_msg("#myModal", 'Sin Stock', 'Producto agotado.')
            }
            else {
                _idsoporte = $(this).data('soporte-id');
                _descripcion_soporte = $(this).data('soporte-descripcion');
                //alert(_descripcion_soporte);
                _idproducto = $(this).data('producto-id');
                _unidades_soporte = $('#selsoportes_'+_idsoporte).val();
                addSoporteCarrito(_idsoporte, _descripcion_soporte,_unidades_soporte,_idproducto);
            }    
          });

   }



  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function mostrar_msg(_modal, _title, _msg){
    if (jQuery(_modal).find('.modal-title').length && jQuery(_modal).find('.modal-body').length){
            jQuery(_modal).find('.modal-title').html(_title);
            jQuery(_modal).find('.modal-body').html('<p>'+_msg+'</p>');
            jQuery(_modal).modal();
    } else {
            alert(_title+": "+_msg);
    }

    jQuery(_modal).on('hidden.bs.modal', function () {
      jQuery(_modal).find('.modal-title').html("");
      jQuery(_modal).find('.modal-body').html("");
    })
};



jQuery(window).resize(function() {
    anchoVentana = jQuery(window).width();
    altoVentana = jQuery(window).height();
    rtime = new Date();
    if (timeout === false) {
        timeout = true;
        setTimeout(resizeend, delta);
    }
});

function resizeend() {
    if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta);
    } else {
        timeout = false;
        checkMobile();
    }               
}

function checkMobile(){
  if (anchoVentana<768){
    jQuery("body").addClass("mobile");
  }else{
    jQuery("body").removeClass("mobile");
  }

  //acciones JS por ser mobile
  //
  if(jQuery("body").hasClass("page-template-000-home")){
    cambioSliderHome();
  }
}


//*******************
//ajustes mobile home
//*******************
//
// función que cambia la imagen de slider home al formato mobile
function cambioSliderHome(){
  jQuery(".swiper-slide").each(function(){
    if (anchoVentana<768){
      jQuery(this).css("background-image","url("+jQuery(this).data("imagebg-mbl")+")");
    }else{
      jQuery(this).css("background-image","url("+jQuery(this).data("imagebg-dsk")+")");
    }
  })
}


function initClickEliminarProductoCesta(){
  jQuery('.js-eliminar-producto_cesta').unbind().on('click', function(e){
        _producto_id = jQuery(this).data('producto_id');
        _idformato = jQuery(this).data('formato_idformato');
        _indice = jQuery(this).attr('data-indice');

        //console.log('elimina '+_producto_id+' >> '+_idformato+' >> '+_indice);


        jQuery('.bt-compra-cesta').attr("disabled", true);
        jQuery('.btn-mas').attr("disabled", true);
        jQuery('.btn-menos').attr("disabled", true);
        jQuery.ajax({
            url: ajaxTheme,
            type: "POST",
            dataType: "json",
            data: '&action=eliminarItem&idproducto='+_producto_id+'&idformato='+_idformato+"&indice="+_indice,
            success: function (response) {
              // _unidades = response;
              //console.log(response);
              _cesta = response['cesta'];
              jQuery('.regalos_cesta_incluir').html(response['html']);
              _cestaCarrito = _cesta;
              if (jQuery('.icono_dto_peque').length > 0 ){
                  jQuery('.img_descuento').hide();
              }
              //jQuery('.item-'+_producto_id+'-'+_idformato).remove();
              jQuery('.producto-indice-'+_indice).remove();

              
              jQuery( ".cont-productos_cesta" ).each(function() {
                  _indice_aux = parseInt(jQuery(this).attr('data-indice_producto'));
                  if (_indice_aux > _indice){
                    jQuery(this).removeClass('producto-indice-'+_indice_aux);
                    _indice_aux--;
                    jQuery(this).attr('data-indice_producto', _indice_aux);
                    jQuery(this).addClass('producto-indice-'+_indice_aux);
                  }
              });

              jQuery( ".btn-cantidad" ).each(function() {
                  _indice_aux = parseInt(jQuery(this).attr('data-index'));
                  if (_indice_aux > _indice){
                    _indice_aux--;
                    jQuery(this).attr('data-index', _indice_aux);
                  }
              });

              jQuery( ".js-precio-total-producto_cesta" ).each(function() {
                  _indice_aux = parseInt(jQuery(this).attr('data-index'));
                  if (_indice_aux > _indice){
                    _indice_aux--;
                    jQuery(this).attr('data-index', _indice_aux);
                  }
              });
              jQuery( ".js-eliminar-producto_cesta" ).each(function() {
                  _indice_aux = jQuery(this).attr('data-indice');
                  if (_indice_aux > _indice){
                    _indice_aux--;
                    jQuery(this).attr('data-indice', _indice_aux);
                  }
              });
              


              jQuery('#etiqueta_'+_producto_id+'_'+_idformato).hide(); 
              jQuery('#unidades_carrito').html(_cesta["unidades_cesta"]);
              jQuery('#unidades_carrito_mobile').html(_cesta["unidades_cesta"]);
              jQuery('#unidades_cesta').html(_cesta["unidades_cesta"]);

              // recalcularCesta(_producto_id, _idformato, _unidades);
              //if(jQuery("body").hasClass("cesta-de-productos")){   
              if (jQuery('.cesta_productos').html() != ''){
                        // calcula gastos si estás en página cesta productos     
                        calcularGastosEnvio(jQuery('input:radio[name=envio]:checked').val(), jQuery('#selpais').val(), jQuery('#selprovincia').val(), false, false, false, jQuery('#ind_facturacion').val()); 
              }else{
                        //recarga solo si está fuera de cesta productos para actualizar ventana carrito
                        recargarCesta(_cesta);
                        jQuery('.bt-compra-cesta').attr("disabled", false);
                        jQuery('.btn-mas').attr("disabled", false);
                        jQuery('.btn-menos').attr("disabled", false);
              }


            },
            error: function (xhr, status) {
            },
            complete: function (xhr, status) {
            }
        });
  });

  jQuery('.js-eliminar-soporte_cesta').on('click', function(){
    _producto_id = jQuery(this).data('producto_id');
    _idsoporte = jQuery(this).data('idsoporte');

      if(jQuery("body").hasClass("cesta-de-productos")){    
          //objeto para cesta compra
          _objItem=jQuery(this).closest(".cont-regalo-productos_cesta");
      }else{
          //objeto para modal carrito
          _objItem=jQuery(this).closest(".li-carrito");
      }    
          jQuery.ajax({
              url: ajaxTheme,
              type: "POST",
              dataType: "json",
              data: '&action=eliminarSoporte&idproducto='+_producto_id+'&idsoporte='+_idsoporte,
              success: function (response) {
                _cesta = response;
                _cestaCarrito = _cesta;

                _objItem.remove();
                recargarCesta(_cesta);
              },
              error: function (xhr, status) {
              },
              complete: function (xhr, status) {
              }
          });
  });



}