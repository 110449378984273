(function($) {

/*FICHA PRODUCTO*/
 
 /*loader_spinner = function(_target,_status){
    //target por ID porque se hace por DOM javascript y NO jquery
    var targetSpinner= document.getElementById(_target);
    var targetJquery=$("#"+_target);
    if(targetJquery.hasClass("js-spinner-holder")){
        targetFade=".js-spinner-holder"
    }else{
        targetFade="#"+_target;
    }

    if(_status=="show"){
        $(targetSpinner).html("");
        new Spinner({color:'#fff', lines: 12, length: 5, width: 4}).spin(targetSpinner);
        $(targetFade).fadeIn();
    }else{
        $(targetFade).fadeOut(function(){
            $(targetSpinner).html("");
        });
    }

}
*/

 initOpciones = function(){
  _formatoElegido = $('#selformato').val();
  _stock = $('option:selected', '#selformato').attr('stock');
  _txt = '';
  _clase = '';
  switch (_stock){
    case '0':
      _txt = '<b>Disponibilidad alta:</b> Más de '+phpVars.limite_media+' uds. en stock';
      _clase = 'disponibilidad-alta';
      break;
    case '1':
      _txt = '<b>Disponibilidad media:</b> Menos de '+phpVars.limite_media+' uds. en stock';
      _clase = 'disponibilidad-media';
      break;
    case '2': 
      _txt = '<b>Disponibilidad baja:</b> Menos de '+phpVars.limite_baja+' uds. en stock';
      _clase = 'disponibilidad-baja';
      break;
    case '3':
      _txt = '<b>Agotado:</b> Agotado';
      _clase = 'disponibilidad-agotado';
      break;
    default:
      _txt = '';
      break;      
    }
    $('#stock-formato').addClass(_clase);
    $('#stock-formato').html(_txt);
}

initFormatos = function(numopciones_sabores, _idformato){
  _idformatoPrincipal = _idformato;
  $('#selformato').val(_idformatoPrincipal);

  $('.bloque-pastillas').hide();
  $('.ficha-precios_y_descuentos').hide();
  
  $('#pastillas-'+_idformatoPrincipal).show();

  $('#datos-formato-'+_idformatoPrincipal).show();

  if (numopciones_sabores > 0){

    $('.rows-configuracion-sabores').show();
    $('.opciones_sabores').hide();

    $('.sabores_'+_idformatoPrincipal).show();
  }
  
  $('.formatos-ofertas').hide();
  $('#oferta_formato_'+_idformato).show();
}
/*FICHA PROMOCIÓN*/
initFichaPromocion = function(_promocion_fija){
        
        $( ".js-producto_primero" ).each(function( index ) {
            _op = $(this).find("option:first-child").val();
              $(this).val(_op);
        });
        
        $( ".js-cantidad-producto-promo" ).each(function( index ) {
              _op = $(this).find("option:first-child").val();
              $(this).val(_op);
        });
        
        $( ".js-precio_ud_promo" ).each(function( index ) {
            if ($(this).data('fijo')!='1'){
              $(this).val('0,00');
            }
        });
        $( ".js-precio_total_promo" ).each(function( index ) {
            if ($(this).data('fijo')!='1'){
              $(this).val('0,00');
            }
        });
       
        $('.opciones_sabores').each(function( index ) {
            _op = $(this).find("option:first-child").val();
            $(this).val(_op);
        });
        //$('#total_promocion').val(0);
        //$('#total_promocion_sin_iva').val(0);


        $('.texto-aviso_promocion').hide();
        $('#promocion-insuficiente').hide();
        //desactivarBotones();
      
}

activarBotones = function(){
	  $('#js-bt-add-promocion').prop('disabled', false);
    $('#js-bt-comprar-promocion').prop('disabled', false);
    $('#js-bt-add-promocion').css('opacity', '1');
    $('#js-bt-comprar-promocion').css('opacity', '1');
}

desactivarBotones = function(){
	  $('#js-bt-add-promocion').prop('disabled', true);
    $('#js-bt-comprar-promocion').prop('disabled', true);
    $('#js-bt-add-promocion').css('opacity', '0.3');
    $('#js-bt-comprar-promocion').css('opacity', '0.3');
}

comprobarOpcion = function(_numproducto){
  $('.texto-aviso_promocion-total').hide();
  _udtotal_fila = 0;
  //console.log('num::'+_numproducto);
  $('[id^=l-producto-'+_numproducto+'-]').each(function( index ) {
      _id_tmp = $(this).attr('id');
      _id_1 = _id_tmp.split('-');
      _id = 'producto-'+_numproducto+'-'+_id_1[3]+'-unidades';
      //console.log('id '+_id);
      _u = $('#'+_id).val();
     // console.log('valor::'+_u);
      _udtotal_fila += Number(_u);
      _u_obligatorias = $(this).data('unidades-obligatorias');
  });
//console.log('ud '+_udtotal_fila+' oblig '+_u_obligatorias);
  if (Number(_udtotal_fila) < Number(_u_obligatorias)){//no llega --> puede seguir comprando
    $('#promocion-completa-'+_numproducto).hide();
    $('#promocion-superior-'+_numproducto).hide();
    $('#promocion-insuficiente-'+_numproducto).show();
    $('.div-item-inactivo-'+_numproducto).show();
    _error = true;

  }
  else{//promo completa y no mostrar bt añadir
    if (Number(_udtotal_fila) == Number(_u_obligatorias)){
        $('#promocion-completa-'+_numproducto).show();
        $('#promocion-insuficiente-'+_numproducto).hide();
        $('#promocion-superior-'+_numproducto).hide();
        $('.div-item-inactivo-'+_numproducto).hide();
        _error = false;
    }
    else {//
      $('#promocion-completa-'+_numproducto).hide();
      $('#promocion-insuficiente-'+_numproducto).hide();
      $('#promocion-superior-'+_numproducto).show();
      $('.div-item-inactivo-'+_numproducto).hide();
      _error = true;
    }
  }  

  return _error;
}

comprobarOpcionesRellenas = function(){
  var _error = false;

  $('#FPromo .opciones_sabores').each(function( index ) {
        _error_parcial = false;
//alert($(this).attr('name') + ' :: '+$(this).val());
        if ($(this).val() == ''){
          _error_parcial = true;
        }
        if (_error_parcial){
          _error = true;
        }
  });  

  if (!_error){
      $('#FPromo .js-control-relleno').each(function( index ) {
              _error_parcial = false;
              if ($(this).val() == ''){
                _error_parcial = true;
              }
              if (_error_parcial){
                _error = true;
              }
        });  

  }


  return _error;
}



comprobarPromocion = function(){
    var _error = false;


    $('.grupo-promo').each(function( index ) {
        _numproducto = $(this).data('numgrupo');
        _error_parcial = comprobarOpcion(_numproducto);
        if (_error_parcial){
          _error = true;
         //  alert('error '+$(this).data('numgrupo')+' '+_error);
        }
       
    });  



    if (_error){
          $('.texto-aviso_promocion-total').hide();
         // $('#promocion-insuficiente').show();
          //desactivarBotones();
    }
    else {
          $('.texto-aviso_promocion-total').hide();
          $('#promocion-completa').show();
         // activarBotones();
    }
    
//     alert('compro::'+_error);
    return _error;
}

calcularTotalesPromocion = function(_importe_minimo, _importe_permitido){
  var _total = 0;
  var _error = false;
 
  $('#FPromo .js-precio_total_promo').each(function( index ) {
      if ($(this).val()!=''){
        _parcial = pasarJS($(this).val());
        _total = Number(_total) + Number(_parcial);
        //console.log('parcial::'+_parcial+' total:: '+_total);
      }  
  });

  _total = _total.toFixed(2);
  _total_print = _total;
  _total_print = formatNumber.new(_total);
  $('#total_promocion').html(_total_print);
  _total_extra = pasarJS($('#total_promocion_extra').html());
  _total_promocion = Number(_total) + Number(_total_extra);

  _total_promocion = _total_promocion.toFixed(2);
  _total_promocion_print = formatNumber.new(_total_promocion);
  $('#total_promocion_final').html(_total_promocion_print);
  _importe_minimo = Number(_importe_minimo); 
  _importe_permitido = Number(_importe_permitido);
//console.log(_total+' .. '+_importe_minimo+' :: '+_importe_permitido);
  if (_total == _importe_minimo){
    //activarBotones();
    $('.texto-aviso_promocion-total').hide();
  //  $('#promocion-completa').show();
  }
  else {
    if (_total > _importe_minimo){
     // activarBotones();
      $('.texto-aviso_promocion-total').hide();
      //solo debe aparecer si todas las opciones están ok
     
      _most = true;
      $('[id^=promocion-insuficiente-]').each(function( index ) { 
          if ($(this).is(":visible")){
            _most = false;
          }
      } );
      $('[id^=promocion-superior-]').each(function( index ) { 
          if ($(this).is(":visible")){
            _most = false;
          }
      }); 

      if (_most){
        $('#promocion-superior').show();
      }

      
    }
    else {//mirar si llega con la desviación
      if(_total >= _importe_permitido){
      //mirar las cajas
       // activarBotones();
        $('.texto-aviso_promocion-total').hide();
        $('#promocion-minimo').show();
      }
      else {
          $('.texto-aviso_promocion-total').hide();
          //$('#promocion-insuficiente').show();
        //  desactivarBotones();
          _error = true;
      }
    }
  }




}
/*comprobarPromocion = function(_importe_minimo, _importe_permitido, _promocion_fija){
	_total = 0;
  _error = false;
  if (_promocion_fija){
    _total = Number($('#total_promocion').html());

  }
  else {
    $('.js-precio_total_promo').each(function( index ) {
      if ($(this).val()!=''){
        _parcial = pasarJS($(this).val());
        _total = Number(_total) + Number(_parcial);
      }  
  	});
  }  
  _total = _total.toFixed(2);
  _total_print = _total;
  _total_print = formatNumber.new(_total);
	$('#total_promocion').html(_total_print);
  _total_extra = pasarJS($('#total_promocion_extra').html());
  _total_promocion = Number(_total) + Number(_total_extra);

  _total_promocion = _total_promocion.toFixed(2);
  _total_promocion_print = formatNumber.new(_total_promocion);
  $('#total_promocion_final').html(_total_promocion_print);
	_importe_minimo = Number(_importe_minimo); 
  _importe_permitido = Number(_importe_permitido);
//console.log(_total+' .. '+_importe_minimo+' :: '+_importe_permitido);
  if (_total == _importe_minimo){
		activarBotones();
		$('.texto-aviso_promocion-total').hide();
	//	$('#promocion-completa').show();
	}
	else {
		if (_total > _importe_minimo){
			activarBotones();
			$('.texto-aviso_promocion-total').hide();
			//$('#promocion-superior').show();
		}
		else {//mirar si llega con la desviación
			if(_total >= _importe_permitido){
			//mirar las cajas
				activarBotones();
				$('.texto-aviso_promocion-total').hide();
				$('#promocion-minimo').show();
			}
			else {
				  $('.texto-aviso_promocion-total').hide();
		    	$('#promocion-insuficiente').show();
		    	desactivarBotones();
          _error = true;
			}
		}

	}





  return _error;
}*/


/*LISTADO PRODUCTOS*/
  initListado = function(_array_taxonomias, _familia_activa){


   $('.col-item-listado').hide();
   $('#numtotal').html('');  
   $('#ordenaciones').val('orden');
   tinysort('div.col-item-listado',{data:'orden', order:'asc'});
  
   $('.checkbox-filtrar').prop('checked', false);
   $('.ckcomparar').prop('checked', false);
   
   if (_familia_activa != ''){
    //$('#cktodos').prop('checked', false);
    $('#inputcheckbox_'+_familia_activa).prop('checked', true);
    filtrar(_array_taxonomias);
   }
   else {
    $('.checkbox-filtrar').prop('checked', false);
    //$('#cktodos').prop('checked', true);
    filtrar(_array_taxonomias);
   }

   $('#mostrar-todos').on('click', function(){
      $('.checkbox-filtrar').prop('checked', false);
      filtrar(_array_taxonomias);
   });
 }  

limpiarComparador = function(){ 
  for (c=1;c<4;c++){
      $('.col-comparador-'+c).html('');
      $('.col-capacidad-'+c).html('');
      $('.col-potencia-'+c).html('');
      $('.col-peso-'+c).html('');
      $('.col-dimensiones-'+c).html('');
      $('.col-equipamiento-'+c).html('');
  }    
}

filtrar = function(_array_taxonomias){

    $('.col-item-listado').hide();
    var _taxonomias = [];
    var num_ck = 0;
      $( ".checkbox-filtrar" ).each(function() {

         if($(this).is(':checked')) { 
          //alert(jQuery(this).data('idtaxonomia'));
          _taxonomia = $(this).data('idtaxonomia');
          _taxonomias.push(_taxonomia);
          _titulo_nuevo = $(this).data('titulo-filtro');
          _contenido_nuevo = $(this).data('content-filtro');
          num_ck++;
         }
    });
      var busqueda = [];
      var mostrar_busqueda = [];
      //console.log(_array_taxonomias[0]);
      for (j=0;j<_array_taxonomias.length;j++){
        
      
        _a = _array_taxonomias[j].split('#');

        _a.pop();//quito el último

        for (z=0;z<_a.length;z++){
          _a[z] = _a[z].replace('"', '');
        }

        busqueda[j]='';
        mostrar_busqueda[j] = '0';
       
        for (i=0;i<_taxonomias.length;i++){

         if (jQuery.inArray(_taxonomias[i].toString(), _a) >=0){
            busqueda[j] = busqueda[j]+_taxonomias[i].toString()+'#';
        } 
      }

      if (num_ck == 1){
        $('#titulo_listado').html(_titulo_nuevo);
        $('#contenido_listado').html(_contenido_nuevo);
      }
      else{//si es igual a 0 o ninguna
          $('#titulo_listado').html($('#titulo_listado').data('valordefecto'));
          $('#contenido_listado').html($('#contenido_listado').data('valordefecto'));
      }

    }
        


    $( ".col-item-listado" ).each(function() {
      _mostrar = true;
            
      _t = $(this).data('taxonomias').split('#');
      _t.pop();//quito el último porque es vacío
            


      for (x=0;x<busqueda.length;x++){
        _b = busqueda[x].split('#');

        _b.pop();
        if (_b.length > 0){

        _parcial = '0';
             
        for (w=0;w<_t.length;w++){
          if (_parcial == '0'){

            if (jQuery.inArray(_t[w], _b) >=0){
              _parcial = '1';
            }
          }
        }

        mostrar_busqueda[x] = _parcial;
      } 
      else {
        mostrar_busqueda[x] = '1';
        }
        }

            //console.log(mostrar_busqueda);
            //console.log('////');

        if (jQuery.inArray('0', mostrar_busqueda) >=0){
        _mostrar = false;
      }
//console.log('----');
            

      if (_mostrar){
        $(this).show();
      }
            
        }); 
        
        $('#numtotal').html('<strong>'+$( ".col-item-listado:visible" ).size()+' artículos</strong>');
  }



})(jQuery);




(function($) {

/*
*  new_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param $el (jQuery element)
*  @return  n/a
*/

 new_map = function( $el ) {
  
  // var
  var $markers = $el.find('.marker');
  
  
  // vars
  var args = {
    zoom    : 16,
    center    : new google.maps.LatLng(0, 0),
    mapTypeId : google.maps.MapTypeId.ROADMAP
  };
  
  
  // create map           
  var map = new google.maps.Map( $el[0], args);
  
  
  // add a markers reference
  map.markers = [];
  
  
  // add markers
  $markers.each(function(){
    
      add_marker( $(this), map );
    
  });
  
  
  // center map
  center_map( map );
  
  
  // return
  return map;
  
}

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param $marker (jQuery element)
*  @param map (Google Map object)
*  @return  n/a
*/

add_marker = function( $marker, map ) {

  // var
  var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

  // create marker
  var marker = new google.maps.Marker({
    position  : latlng,
    map     : map
  });

  // add to array
  map.markers.push( marker );

  // if marker contains HTML, add it to an infoWindow
  if( $marker.html() )
  {
    // create info window
    var infowindow = new google.maps.InfoWindow({
      content   : $marker.html()
    });

    // show info window when marker is clicked
    google.maps.event.addListener(marker, 'click', function() {

      infowindow.open( map, marker );

    });
  }

}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param map (Google Map object)
*  @return  n/a
*/

center_map = function( map ) {

  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  $.each( map.markers, function( i, marker ){

    var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

    bounds.extend( latlng );

  });

  // only 1 marker?
  if( map.markers.length == 1 )
  {
    // set center of map
      map.setCenter( bounds.getCenter() );
      map.setZoom( 18 );
  }
  else
  {
    // fit to bounds
    map.fitBounds( bounds );
  }

}

/*  FUNCIONES CARRITO  */

/*
 * mostrar alerta carrito
 */
alerta_carrito=function(_texto){
  $(".js-alerta-carrito p.texto").html(_texto);
  $(".js-alerta-carrito").addClass("activo");
  setTimeout(function(){
    $(".js-alerta-carrito").removeClass("activo");
  },3000);
}

cargarCesta=function(){
  $('.bt-compra-cesta').attr("disabled", true);
  $('.btn-mas').attr("disabled", true);
  $('.btn-menos').attr("disabled", true);
 $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: '&action=cargarCesta',
        action: "cargarCesta",
        success: function (response) {
          _cesta = response;
          _cestaCarrito = _cesta;
          //console.log(_cesta);
          cargarUnidades();
        },
        error: function (xhr, status) {
           

        },
        complete: function (xhr, status) {
             
        }
    }); 
}

cargarUnidades=function(){
  $('.bt-compra-cesta').attr("disabled", true);
  $('.btn-mas').attr("disabled", true);
  $('.btn-menos').attr("disabled", true);
 $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: '&action=cargarUnidades',
        action: "cargarUnidades",
        success: function (response) {
          _unidades = response;
          _unidadesCarrito = _unidades;
          //console.log(_cesta);
          $('.bt-compra-cesta').attr("disabled", false);
          $('.btn-mas').attr("disabled", false);
          $('.btn-menos').attr("disabled", false);

        },
        error: function (xhr, status) {
           

        },
        complete: function (xhr, status) {
             
        }
    }); 
}

addPromocion=function(_href){
    //console.log($('#FPromo').serialize());
    //carga de API WS TLC todos los centros de la promo
//console.log($('#FPromo').serialize());

    $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: $('#FPromo').serialize(),
        action: "guardarPromocion",
        success: function (response) {
          _cesta = response;
          _cestaCarrito = _cesta;
          //console.log(_cesta);
          recargarCesta(_cesta);
          if (_href!=''){
            document.location.href=_href; 
          } 
        },
        error: function (xhr, status) {
           

        },
        complete: function (xhr, status) {
             
        }
    });

}

addCarrito=function(_id, _href){
  $('#boton-carrito').attr('data-unidades', $('#item-cantidad').val());
  $('#boton-comprar').attr('data-unidades', $('#item-cantidad').val());
 
  _idfElegido = $('#formato_idformato-cesta').val();
  $(".sabores_"+_idfElegido).each(function( index ) {
            //console.log( index + ": " + $( this ).text() );
            _valor = $(this).val();
            _name_input = $(this).attr('id');
           // alert(_name_input);
            $("#fProducto input:last").append('<input type="hidden" name="'+_name_input+'-cesta" id="'+_name_input+'-cesta" value="'+_valor+'">');
  });

  
  $(".regalos").each(function( index ) {
      _valor = $(this).val();
      _name_input = $(this).attr('id');
      _tmp = _name_input.split('_');
      _idregalo = _tmp[1];
      _numregalo = _tmp[2];
     // console.log(_valor+'::'+_idregalo+':::: '+'#regalo-'+_idregalo+'-opcion-'+_numregalo);
      $('#regalo-'+_idregalo+'-opcion-'+_numregalo).val(_valor);
  });


    //carga de API WS TLC todos los centros de la promo
    $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: $('#fProducto').serialize(),
        action: "guardarCesta",
        success: function (response) {
          _cesta = response;
          _cestaCarrito = _cesta;
         // console.log(_cesta);
          recargarCesta(_cesta);
          if (_href!=''){
            document.location.href=_href; 
          }  
        },
        error: function (xhr, status) {
           

        },
        complete: function (xhr, status) {
             
        }
    });



}

addSoporteCarrito=function(_idsoporte, _descripcion_soporte,_unidades_soporte,_idproducto){
  //carga de API WS TLC todos los centros de la promo

    $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: "&action=guardarSoporte&idsoporte="+_idsoporte+"&descripcion_soporte="+_descripcion_soporte+"&unidades_soporte="+_unidades_soporte+"&idproducto="+_idproducto,
        action: "guardarSoporte",
        success: function (response) {
          _cesta = response;
           _cestaCarrito = _cesta;
          //console.log(_cesta);
            recargarCesta(_cesta);
            alerta_carrito('¡Genial!<br> Has añadido <strong>'+_unidades_soporte+' de '+_descripcion_soporte+'</strong><br> a tu carrito de la compra ');

        },
        error: function (xhr, status) {
           

        },
        complete: function (xhr, status) {
             
        }
    });
}

recargarCesta=function(_cesta){
  //console.log ("funcion recargarCesta");
  //console.log(_cesta);
  items_cesta = _cesta['items'];
  soportes = _cesta['soportes'];

  precio_total_pedido = 0;
  _unidades_total = 0;
  precio_total = 0;
  $("#resumen-total-carrito").html('');
  $("#resumen-carrito").html('');
  for (j=0;j<items_cesta.length;j++){
    _permalink = items_cesta[j]['formato_permalink'];
    _idformato = items_cesta[j]['formato_idformato'];
    _idproducto = items_cesta[j]['idproducto'];
    _imagen = items_cesta[j]['formato_imagen_url'];
    _url = items_cesta[j]['formato_url'];
    _alt = items_cesta[j]['formato_imagen_alt'];
    _unidades = items_cesta[j]['formato_unidades'];
    _name = items_cesta[j]['nombre_formato'];
    

    /*_precio  = items_cesta[j]['formato_precio'];
    _precio1  = items_cesta[j]['formato_precio1'];
    _precio2  = items_cesta[j]['formato_precio2'];
    

    if (_precio1 != ''){
      _precio = _precio1;
    }
    if (_precio2 != ''){
      _precio = _precio2;
    }*/

    switch (items_cesta[j]['formato_tipo_oferta']){
        case '0'://no tiene oferta
            precio = items_cesta[j]['formato_precio'];
            precio_total = precio * items_cesta[j]['formato_unidades'];
          break;
        case '1'://precio especial
            precio = items_cesta[j]['formato_precio1'];
            precio_total = precio * items_cesta[j]['formato_unidades'];  
          break;
        case '2'://porcentaje
            precio = items_cesta[j]['formato_precio2'];
            precio_total = precio * items_cesta[j]['formato_unidades'];  
          break;
        case '3'://la X unidad sale a X%
            if (items_cesta[j]['formato_unidades'] >= items_cesta[j]['formato_unidades3'] ){
              //entra por la oferta, calcular cuántas unidades tienen descuento
              unidades_dto = parseInt(items_cesta[j]['formato_unidades']/items_cesta[j]['formato_unidades3']);
              unidades_sin_dto = items_cesta[j]['formato_unidades'] - unidades_dto;

              precio = items_cesta[j]['formato_precio'];
              precio_dto = precio - (precio * items_cesta[j]['formato_porcentaje3']/100);
              //precio_dto = (precio_dto * 100) / 100;
              precio_dto = precio_dto.toFixed(2);
              precio_total = (unidades_sin_dto * precio) + (unidades_dto * precio_dto);
              //precio_total = (precio_total * 100) / 100;
              //precio_total = precio_total.toFixed(2);
            } 
            else {
              precio = items_cesta[j]['formato_precio'];
              precio_total = precio * items_cesta[j]['formato_unidades'];
            } 
          break;
        case '4'://La X unidad sale a X.
            if (items_cesta[j]['formato_unidades'] >= items_cesta[j]['formato_unidades4'] ){
              //entra por la oferta, calcular cuántas unidades tienen descuento
              unidades_dto = parseInt(items_cesta[j]['formato_unidades']/items_cesta[j]['formato_unidades4']);
              unidades_sin_dto = items_cesta[j]['formato_unidades'] - unidades_dto;

              precio = items_cesta[j]['formato_precio'];
              precio_dto = items_cesta[j]['formato_precio4'];
              precio_total = (unidades_sin_dto * precio) + (unidades_dto * precio_dto);
              //precio_total = (precio_total * 100) / 100;
              //precio_total = precio_total.toFixed(2);
            } 
            else {
              precio = items_cesta[j]['formato_precio'];
              precio_total = precio * items_cesta[j]['formato_unidades'];
            } 
          break;
        case '5': //Comprando X unidades el precio sale a X€
            if (items_cesta[j]['formato_unidades'] >= items_cesta[j]['formato_unidades5'] ){
              precio = items_cesta[j]['formato_precio5'];
              precio_total = precio * items_cesta[j]['formato_unidades'];  
             // console.log('llega con '+items_cesta[j]['formato_unidades']+' '+items_cesta[j]['formato_precio5']);
            }
            else {
              precio = items_cesta[j]['formato_precio'];
              precio_total = precio * items_cesta[j]['formato_unidades'];
            } 
          break;  
      }   


    precio_total_pedido += precio_total;
    _unidades_total += parseInt(_unidades);
    _nuevoItemCarrito = '<li class="li-carrito producto-indice-'+j+'"><div class="item-carrito d-flex">';
    _nuevoItemCarrito += '<a href="'+_permalink+'" class="enlace-carrito">';
    _nuevoItemCarrito += '<img class="img-carrito" src="'+_imagen+'" alt="'+_alt+'">';
    _nuevoItemCarrito += '<p class="nombre-carrito h3">'+_name+'</p>';
    _nuevoItemCarrito += '<p class="datos-carrito"><span class="cantidad-carrito">Cantidad '+_unidades+'</span><br>';
    _precio_total_print = formatearImprimir(precio_total, 2);
    _nuevoItemCarrito += '<span class="precio-carrito">'+_precio_total_print+' €</span></p></a>';

    _nuevoItemCarrito += '<div class="cont-eliminar"><a href="javascript:;" class="btn-eliminar  js-eliminar-producto_cesta" data-producto_id="'+_idproducto+'" data-formato_idformato="'+_idformato+'" data-indice="'+j+'"><span>Eliminar</span><img class="img-dto" src="/wp-content/themes/grupo-solera/dist/images/ficha/ico-basura.png" alt="Eliminar" width="12" height="18"></a></div>';

    _nuevoItemCarrito += '</li><hr>';

    $("#resumen-carrito").append(_nuevoItemCarrito);

    if (soportes != '' && soportes != 'undefined' && soportes != undefined){
      for (s=0;s<soportes.length;s++){
        //console.log('pasa a añadir soporte');
          if (soportes[s]['idproducto'] == items_cesta[j]['idproducto']){
          
                _nuevoItemCarrito = '<li class="li-carrito"><div class="item-carrito item-soporte d-flex">';
                _nuevoItemCarrito += '<a href="javascript:;" class="enlace-carrito ">';
                _nuevoItemCarrito += '<p class="nombre-carrito h3">'+soportes[s]['descripcion_soporte']+'</p>';
                _nuevoItemCarrito += '<p class="datos-carrito"><span class="cantidad-carrito">Cantidad '+soportes[s]['unidades_soporte']+'</span><br>';
                _nuevoItemCarrito += '<span class="precio-carrito">0,00 €</span></p></a>';
                _nuevoItemCarrito += '<div class="cont-eliminar"><a href="javascript:;" class="btn-eliminar js-eliminar-soporte_cesta" data-producto_id="'+soportes[s]['idproducto']+'" data-idsoporte="'+soportes[s]['idsoporte']+'"><span>Eliminar</span><img class="img-dto" src="/wp-content/themes/grupo-solera/dist/images/ficha/ico-basura.png" alt="Eliminar" width="12" height="18"></a></div>';
                _nuevoItemCarrito += '</div></li><hr>';
                $("#resumen-carrito").append(_nuevoItemCarrito);
                //console.log($("#resumen-carrito").html());
          }
      }  
    }  

     
  }//fin for 
//total
  _itemTotal = '<p class="total-carrito">('+_unidades_total;
  _precio_total_pedido_print = formatearImprimir(precio_total_pedido, 2);
  _itemTotal += ') Unidades<span class="pull-xs-right"><b>TOTAL: '+_precio_total_pedido_print;
  _itemTotal += ' €</b></span></p>';
  _itemTotal += '<a href="/cesta-de-productos/" type="button" onclick="jQuery(\'.modal-articulos-carrito\').removeClass(\'activo\');" class="btn btn-primary btn-block btn-realizar_pedido js-btn-realizar_pedido">Realizar Pedido</a>';
   
   $("#resumen-total-carrito").html(_itemTotal);
   $('#unidades_carrito').html(_unidades_total); 

  initClickEliminarProductoCesta();
}


recalcularCesta = function(_idproducto, _idformato, _unidades, _index){
  $('.bt-compra-cesta').attr("disabled", true);
  $('.btn-mas').attr("disabled", true);
  $('.btn-menos').attr("disabled", true);
  //console.log('index:: '+_index);
  $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: "&action=recalcularCesta&idproducto="+_idproducto+"&idformato="+_idformato+"&unidades="+_unidades+"&index="+_index,
        success: function (response) {
            //console.log(response);
            _mostrar_icono = response['mostrar_icono'];
            _precio_unidad_print = formatearImprimir(response['precio_unidad'], 2);
            _precio_unidad = _precio_unidad_print+' €';
            _unidades = response['total_unidades'];
            _precio_fila_print = formatearImprimir(response['precio_fila'], 2);
            _precio_fila = _precio_fila_print+' €';
            _precio_total_print = formatearImprimir(response['total_pedido'], 2);
            _precio_total = _precio_total_print;
           // _unidadesTotal = response['unidades_total'];
           if (_mostrar_icono){
              $('#imagen_unidad_'+_idproducto+'_'+_idformato).show();
              $('#etiqueta_'+_idproducto+'_'+_idformato).show();  
           }
           else{
              $('#imagen_unidad_'+_idproducto+'_'+_idformato).hide();
              $('#etiqueta_'+_idproducto+'_'+_idformato).hide();  
           }

           $('.img_descuento').hide();
           $(".icono_dto_peque").each(function(){
              if ($(this).is(":visible")){
                $('.img_descuento').show();
              }
            });  



            $('#precio_unidad_'+_idproducto+'_'+_idformato).val(_precio_unidad);
           
           // $('#precio_fila_'+_idproducto+'_'+_idformato).val(_precio_fila);
           //console.log('ini::'+$('.precio-total-producto_cesta[data-index='+_index+']').val());

            $('.precio-total-producto_cesta[data-index='+_index+']').val(_precio_fila);
//console.log('fin::'+$('.precio-total-producto_cesta[data-index='+_index+']').val());
         // console.log('despues index:: '+_index);
   
            $('#unidades_carrito').html(_unidades);
            $('#unidades_cesta').html(_unidades);

            $('#unidades_carrito').html(_unidades);

            calcularGastosEnvio($('input:radio[name=envio]:checked').val(), $('#selpais').val(), $('#selprovincia').val(), false, false, false, $('#ind_facturacion').val()); 

            

            $('.regalos_cesta_incluir').html(response['html']);

        },
        error: function (xhr, status) {
           

        },
        complete: function (xhr, status) {
             
        }
  });
}

calcularGastosEnvio = function(_tipopedido, _pais, _provincia, _recogerSolera, _contrareembolso, _recargoequivalencia, _indfacturacion){
  $('.bt-compra-cesta').attr("disabled", true);
  /*$('.btn-mas').attr("disabled", true);
  $('.btn-menos').attr("disabled", true);*/
  $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: "&action=calcularGastosEnvio&tipo_pedido="+_tipopedido+"&pais="+_pais+"&provincia="+_provincia+"&recogerSolera="+_recogerSolera+"&contrareembolso="+_contrareembolso+"&recargoequivalencia="+_recargoequivalencia,
         success: function (response) {
            _respuesta = response['mensaje'];
           //console.log(response);
            if ( $("#formEnvio .alert").length > 0 ) {//solo lo hará en la página del resumen total
               $('#total').val($('#totalOriginal'));
               $('#puntos_importe').val(0);
               $('#abonos').val(0);
               $('.abono-par').removeClass('seleccionado');
               $('.abono-impar').removeClass('seleccionado');
               $('.ck_descontar_saldo').prop('checked', false);
               if ($('#ex1').length > 0 ){//solo si hay puntos
                 var minval = $('#ex1').data('slider').min; //store slider min value
                 $('#ex1').slider('setValue', minval);
                 //$('#puntos_descontar').html('0');
                 $('#puntos_descontar').val('0');
                 $('#importe_puntos_descontar').html('0,00');
              }
            }   
           switch (_respuesta){
              case 'error_datos':
                    //mostrar_msg('#myModal', 'Error', 'Ocurrió un error al calcular los gastos de envío');
                    $('#gastos_envio').html('');
                    $('#total_pedido').html('');
                    $('#btn-comprar').attr("disabled", true);
                    if ( $("#formEnvio .alert").length > 0 ) {//solo lo hará en la página del resumen total
                      alerta($("#formEnvio .alert"),"alert-danger","Comprueba que has rellenado correctamente los campos obligatorios",false);
                    }  
                    break;
              case 'error_maximo_peso_urgente':
                    $('#gastos_envio').html('');
                    $('#total_pedido').html('');
                    mostrar_msg('#myModal', 'Error', 'No puede elegir portes urgentes, el pedido excede el peso exigido para este tipo de envío.');
                    $('#btn-comprar').attr("disabled", true);
                    break;
                  
              case 'ok':
                      envio = response['envio'];
                      contrareembolso = response['contrareembolso']; 

                      gastos_envio_print = formatearImprimir(envio, 2);
                      $('#gastos_envio').html(gastos_envio_print);
                     
                         calcularTotalPedido(_recargoequivalencia, _indfacturacion, _contrareembolso);
                         $('#btn-comprar').attr("disabled", false);
                         if ( $("#formEnvio .alert").length > 0 ) {//solo lo hará en la página del resumen total
                            $("#formEnvio .alert").fadeOut();
                            
                            //contrareembolso_print = formatearImprimir(contrareembolso, 2);
                            //$('#gastos_contrareembolso').html(contrareembolso_print);
                            
                            if (_tipopedido=='estandar'){

                              $('#texto_gastos_envio1').html(gastos_envio_print+' €');

                            }
                            else {
                              $('#texto_gastos_envio2').html(gastos_envio_print+' €');
                            }
                            
                         } 
                     break;  
              case 'vacio':
                        $('#gastos_envio').html('0');
                        $('#total_pedido').html('0');
                     break;          
              default: 
                    $('#gastos_envio').html('');
                    $('#total_pedido').html('');
                    $('#btn-comprar').attr("disabled", true);
                    if ( $("#formEnvio").length > 0 ) {
                      alerta($("#formEnvio .alert"),"alert-danger","Comprueba que has rellenado correctamente los campos obligatorios",false);
                    } 
                    break;        
           }
          
        },
        error: function (xhr, status) {
           

        },
        complete: function (xhr, status) {
             
        }
  });

}



calcularTotalPedido = function(_recargoequivalencia, _indfacturacion, _contrareembolso){
  _pais =  $('#pais_fact').val();
  _provincia =  $('#provincia_fact').val();

  $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: "&action=calcularTotalPedido&recargoequivalencia="+_recargoequivalencia+"&pais="+_pais+"&provincia="+_provincia+"&indfacturacion="+_indfacturacion+"&contrareembolso="+_contrareembolso,
        success: function (response) {
          // console.log(response);
           if (response!='KO'){
              
              contrareembolso = response['contrareembolso']
              contrareembolso_print = formatearImprimir(contrareembolso, 2);
              $('#gastos_contrareembolso').html(contrareembolso_print);
              


              //$('#total_pedido').html(response+' €');
              _p = true;
              
              //r = response;
              r = response['total_pedido'];  

              $('#btn-comprar').attr("disabled", false);
              r_print = formatearImprimir(r, 2);
              $('.precio-total_gastos_envioiva').html(r_print);
              $('#total').val(r_print);
              comprobarminimo();
             // alert(r);
              
              if ( $("#formPuntos .alert").length > 0 ) {//solo lo hará en la página del resumen total

                /* if (parseFloat($('#puntos_importe').val()) > 0){ 
                  descontarPuntos();
                  _p = false;
                  //alert('puntos::'+$('.precio-total_gastos_envioiva').html());
                }
                //console.log($('#abonos').val());
                //alert('pasa');
                if (parseFloat($('#abonos').val()) > 0)
                  $('#abonos').val(0);
                  jQuery('.ck_descontar_saldo').each(function(  ) {
                      if ($(this).prop('checked')){
                          _val = $(this).data('importe-descontar');
                          _num = $(this).data('ind-abono');  
                          descontarAbono(_val, _num); 
                          _p = false;
                         // alert('abono::'+$('.precio-total_gastos_envioiva').html());
                       } 
                  }); */
                } 
               // alert('fin::'+$('.precio-total_gastos_envioiva').html());
            //  } 
              

           }   
           else{
            mostrar_msg('#myModal', 'ERROR', 'Ocurrió un error, revise los datos.');
            $('#btn-comprar').attr("disabled", true);
           }
        },
        error: function (xhr, status) {
           

        },
        complete: function (xhr, status) {
             
        }
  });

}

descontarPuntos = function(){
  /*$("#formPuntos .alert").hide();
  $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        //data: "&action=descontarPuntos&importe_descontar="+$('#euros_descontar').val()+"&puntos="+$('#puntos_descontar').html(),
        data: "&action=descontarPuntos&importe_descontar="+$('#euros_descontar').val()+"&puntos="+$('#puntos_descontar').val(),
        success: function (response) {
           //console.log(response);
           if (response!='KO'){
              response_print = formatearImprimir(response, 2);
              $('.precio-total_gastos_envioiva').html(response_print);
              
              $('#btn-comprar').attr("disabled", false);
              if ($('#euros_descontar').val()==''){
                $('#euros_descontar').val();
              }
              _imp = Number($('#euros_descontar').val());
              if (_imp=='' || isNaN(_imp)){
                _imp=0;
              }
              $('#puntos_importe').val(_imp);
              comprobarminimo();
           }   
           else{
            //mostrar_msg('#myModal', 'ERROR', 'Ocurrió un error, revise los datos.');
            alerta($("#formPuntos .alert"),"alert-danger","No se han podido descontar los puntos. Has llegado al máximo descuento aplicable.",false);
           }
        },
        error: function (xhr, status) {
           alerta($("#formPuntos .alert"),"alert-danger","No se han podido descontar los puntos. Has llegado al máximo descuento aplicable.",false);
        },
        complete: function (xhr, status) {
             
        }
  });*/

  $('.texto_gastos_minimos').hide();
  _recoger = false;
  if( $('#recogida').length > 0){
    if( $('#recogida').prop('checked') ) {
      _recoger = true;
    } 
  }       
 // alert('recpoger:: '+_recoger);     
  if ($('#coste_minimo').val()!='' && $('#coste_minimo').val()>0 && $('input:radio[name=envio]:checked').val()!='urgente'){
      if (Number(pasarJS($('.precio-total_gastos_envioiva').html())) <  Number(pasarJS($('#coste_minimo').val()))){
          comprobarminimo2('descontarPuntos');
      }
      else{
        descontarPuntosReales();
      }
  } 
  else{
    descontarPuntosReales();
  }       
}

descontarPuntosReales = function(){
  $("#formPuntos .alert").hide();
  $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        //data: "&action=descontarPuntos&importe_descontar="+$('#euros_descontar').val()+"&puntos="+$('#puntos_descontar').html(),
        data: "&action=descontarPuntos&importe_descontar="+$('#euros_descontar').val()+"&puntos="+$('#puntos_descontar').val(),
        success: function (response) {
           //console.log(response);
           if (response!='KO'){
              response_print = formatearImprimir(response, 2);
              $('.precio-total_gastos_envioiva').html(response_print);
              
              $('#btn-comprar').attr("disabled", false);
              if ($('#euros_descontar').val()==''){
                $('#euros_descontar').val();
              }
              _imp = Number($('#euros_descontar').val());
              if (_imp=='' || isNaN(_imp)){
                _imp=0;
              }
              $('#puntos_importe').val(_imp);
           }   
           else{
            //mostrar_msg('#myModal', 'ERROR', 'Ocurrió un error, revise los datos.');
            alerta($("#formPuntos .alert"),"alert-danger","No se han podido descontar los puntos. Has llegado al máximo descuento aplicable.",false);
           }
        },
        error: function (xhr, status) {
           alerta($("#formPuntos .alert"),"alert-danger","No se han podido descontar los puntos. Has llegado al máximo descuento aplicable.",false);
        },
        complete: function (xhr, status) {
             
        }
  });
}


anularPuntos = function(){
  /*_max = $('#ex1').data('slider').max;
  $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: "&action=anularPuntos&puntos="+_max,
        success: function (response) {
           //console.log(response);
           if (response!='KO'){
              response_print = formatearImprimir(response, 2);
              $('.precio-total_gastos_envioiva').html(response_print);
              comprobarminimo();
              $('#btn-comprar').attr("disabled", false);

              $('#puntos_importe').val(0);
           }   
           else{
            //mostrar_msg('#myModal', 'ERROR', 'Ocurrió un error, revise los datos.');
            alerta($("#formPuntos .alert"),"alert-danger","No se han podido descontar los puntos. Has llegado al máximo descuento aplicable.",false);
           }
        },
        error: function (xhr, status) {
           alerta($("#formPuntos .alert"),"alert-danger","No se han podido descontar los puntos. Has llegado al máximo descuento aplicable.",false);
        },
        complete: function (xhr, status) {
             
        }
  });*/


  $('.texto_gastos_minimos').hide();
  _recoger = false;
  if( $('#recogida').length > 0){
    if( $('#recogida').prop('checked') ) {
      _recoger = true;
    } 
  }       
 // alert('recpoger:: '+_recoger);     
  if ($('#coste_minimo').val()!='' && $('#coste_minimo').val()>0 && $('input:radio[name=envio]:checked').val()!='urgente'){
      if (Number(pasarJS($('.precio-total_gastos_envioiva').html())) <  Number(pasarJS($('#coste_minimo').val()))){
          comprobarminimo2('descontarPuntos');
      }
      else{
        anularPuntosPuntosReales();
      }
  } 
  else{
    anularPuntosPuntosReales();
  }   



}

anularPuntosPuntosReales = function(){
  _max = $('#ex1').data('slider').max;
  $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: "&action=anularPuntos&puntos="+_max,
        success: function (response) {
           //console.log(response);
           if (response!='KO'){
              response_print = formatearImprimir(response, 2);
              $('.precio-total_gastos_envioiva').html(response_print);
              comprobarminimo();
              $('#btn-comprar').attr("disabled", false);

              $('#puntos_importe').val(0);
           }   
           else{
            //mostrar_msg('#myModal', 'ERROR', 'Ocurrió un error, revise los datos.');
            alerta($("#formPuntos .alert"),"alert-danger","No se han podido descontar los puntos. Has llegado al máximo descuento aplicable.",false);
           }
        },
        error: function (xhr, status) {
           alerta($("#formPuntos .alert"),"alert-danger","No se han podido descontar los puntos. Has llegado al máximo descuento aplicable.",false);
        },
        complete: function (xhr, status) {
             
        }
  });
}  


descontarAbono = function(_val, _num){
  $('.texto_gastos_minimos').hide();
  _recoger = false;
  if( $('#recogida').length > 0){
    if( $('#recogida').prop('checked') ) {
      _recoger = true;
    } 
  }       
 // alert('recpoger:: '+_recoger);     
  if ($('#coste_minimo').val()!='' && $('#coste_minimo').val()>0 && $('input:radio[name=envio]:checked').val()!='urgente'){
      if (Number(pasarJS($('.precio-total_gastos_envioiva').html())) <  Number(pasarJS($('#coste_minimo').val()))){
          comprobarminimo2('descontarPuntos');
      }
      else{
        descontarAbonoReales(_val, _num);
      }
  } 
  else{
    descontarAbonoReales(_val, _num);
  }   



}



descontarAbonoReales = function(_val, _num){
  $("#formAbonos .alert").hide();
  $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: "&action=descontarAbonos&importe_descontar="+_val+"&ind="+_num,
        success: function (response) {
           //console.log(response);
           if (response!='KO'){
              response_print = formatearImprimir(response, 2);
              $('.precio-total_gastos_envioiva').html(response_print);
              comprobarminimo();
              $('#btn-comprar').attr("disabled", false);
             // $('#bt-descontar-'+_num).attr("disabled", true);
             _imp = Number($('#abonos').val()) + Number(_val);
             $('#abonos').val(_imp);
           }   
           else{
            //mostrar_msg('#myModal', 'ERROR', 'Ocurrió un error, revise los datos.');
            alerta($("#formAbonos .alert"),"alert-danger","No se ha podido descontar el abono. Has llegado al máximo descuento.",false);
           }
        },
        error: function (xhr, status) {
           alerta($("#formAbonos .alert"),"alert-danger","No se ha podido descontar el abono. Has llegado al máximo descuento.",false);
        },
        complete: function (xhr, status) {
             
        }
  });
}


anularAbono = function(_val, _num){
  $('.texto_gastos_minimos').hide();
  _recoger = false;
  if( $('#recogida').length > 0){
    if( $('#recogida').prop('checked') ) {
      _recoger = true;
    } 
  }       
 // alert('recpoger:: '+_recoger);     
  if ($('#coste_minimo').val()!='' && $('#coste_minimo').val()>0 && $('input:radio[name=envio]:checked').val()!='urgente'){
      if (Number(pasarJS($('.precio-total_gastos_envioiva').html())) <  Number(pasarJS($('#coste_minimo').val()))){
          comprobarminimo2('descontarPuntos');
      }
      else{
        anularAbonoReales(_val, _num);
      }
  } 
  else{
    anularAbonoReales(_val, _num);
  }   



}

anularAbonoReales = function(_val, _num){
  $("#formAbonos .alert").hide();
  $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: "&action=anularAbonos&importe="+_val+"&ind="+_num,
        success: function (response) {
           //console.log(response);
           if (response!='KO'){
              response_print = formatearImprimir(response, 2);
              $('.precio-total_gastos_envioiva').html(response_print);
              comprobarminimo();
              $('#btn-comprar').attr("disabled", false);
              _imp = Number($('#abonos').val()) - Number(_val);
              $('#abonos').val(_imp);

           }   
           else{
            //mostrar_msg('#myModal', 'ERROR', 'Ocurrió un error, revise los datos.');
            alerta($("#formAbonos .alert"),"alert-danger","Ocurrió un error.",false);
           }
        },
        error: function (xhr, status) {
        },
        complete: function (xhr, status) {
             
        }
  });
}


comprobarminimo2 = function(accion){
  //console.log($('#coste_minimo').val()+' '+$('#gastos_envio_minimo').val());
  //console.log('calcula mínimo ::'+$('.precio-total_gastos_envioiva').html()+' ::: '+$('#coste_minimo').val());
  $('.texto_gastos_minimos').hide();
  _recoger = false;
  if( $('#recogida').length > 0){
    if( $('#recogida').prop('checked') ) {
      _recoger = true;
    } 
  }       
 // alert('recpoger:: '+_recoger);     
  if ($('#coste_minimo').val()!='' && $('#coste_minimo').val()>0 && $('input:radio[name=envio]:checked').val()!='urgente'){
      if (Number(pasarJS($('.precio-total_gastos_envioiva').html())) <  Number(pasarJS($('#coste_minimo').val()))){
          
            $.ajax({
                url: ajaxTheme,
                type: "POST",
                dataType: "json",
                data: "&action=cambiarGastosEnvio&gastos="+$('#gastos_envio_minimo').val()+"&recoger="+_recoger,
                success: function (response) {
                  //console.log('llega:: '+response);

                  contrareembolso = response['contrareembolso'];
                  contrareembolso_print = formatearImprimir(contrareembolso, 2);
                  $('#gastos_contrareembolso').html(contrareembolso_print);  

                  r_print = formatearImprimir(response['importe_pedido'], 2);
                  $('.precio-total_gastos_envioiva').html(r_print);
                  $('#total').val(r_print);
                  if (_recoger){
                      $('#gastos_envio').html('0,00');
                      $('.texto_gastos_minimos').hide();
                  }
                  else{
                    $('#gastos_envio').html($('#gastos_envio_minimo').val());
                    $('.texto_gastos_minimos').show();
                  }
                 switch (accion){
                    case 'descontarPuntos':
                          descontarPuntosReales();
                          break;
                    default: 
                          break;      
                  }

                },
                error: function (xhr, status) {
                },
                complete: function (xhr, status) {
                     
                }
          });
      } 
      else{

          if ($('input:radio[name=metodopago]:checked').val()=='contrareembolso'){
              cambiarContrareembolso();
          }

      }
  }
  else{
      if ($('input:radio[name=metodopago]:checked').val()=='contrareembolso'){
              cambiarContrareembolso();
      }
  }
}



comprobarminimo = function(){
  //console.log($('#coste_minimo').val()+' '+$('#gastos_envio_minimo').val());
  //console.log('calcula mínimo ::'+$('.precio-total_gastos_envioiva').html()+' ::: '+$('#coste_minimo').val());
  $('.texto_gastos_minimos').hide();
  _recoger = false;
  if( $('#recogida').length > 0){
    if( $('#recogida').prop('checked') ) {
      _recoger = true;
    } 
  }       
 // alert('recpoger:: '+_recoger);     
  if ($('#coste_minimo').val()!='' && $('#coste_minimo').val()>0 && $('input:radio[name=envio]:checked').val()!='urgente'){
      if (Number(pasarJS($('.precio-total_gastos_envioiva').html())) <  Number(pasarJS($('#coste_minimo').val()))){
          
            $.ajax({
                url: ajaxTheme,
                type: "POST",
                dataType: "json",
                data: "&action=cambiarGastosEnvio&gastos="+$('#gastos_envio_minimo').val()+"&recoger="+_recoger,
                success: function (response) {
                  //console.log('llega:: '+response);

                  contrareembolso = response['contrareembolso'];
                  contrareembolso_print = formatearImprimir(contrareembolso, 2);
                  $('#gastos_contrareembolso').html(contrareembolso_print);  

                  r_print = formatearImprimir(response['importe_pedido'], 2);
                  $('.precio-total_gastos_envioiva').html(r_print);
                  $('#total').val(r_print);
                  if (_recoger){
                      $('#gastos_envio').html('0,00');
                      $('.texto_gastos_minimos').hide();
                  }
                  else{
                    $('#gastos_envio').html($('#gastos_envio_minimo').val());
                    $('.texto_gastos_minimos').show();
                  }
                  /*_recargoequivalencia = false;
                  if ($('#inputcheckbox_recargo').length > 0){
                    if( $('#inputcheckbox_recargo').prop('checked') ) {
                      _recargoequivalencia = true;
                    }
                  }

                  _indfacturacion = -1;   
                  if ($('#direcionFacturacion').length > 0){
                    if ($('#direcionFacturacion').is(":visible")){
                      _indfacturacion = parseInt($('#direcionFacturacion').prop('selectedIndex'))+1;
                    }
                    else{
                        
                    }
                  }  */
                  //console.log('llega');
                  //calcularTotalPedido(_recargoequivalencia, _indfacturacion);
                },
                error: function (xhr, status) {
                },
                complete: function (xhr, status) {
                     
                }
          });
      } 
      else{

          if ($('input:radio[name=metodopago]:checked').val()=='contrareembolso'){
              cambiarContrareembolso();
          }

      }
  }
  else{
      if ($('input:radio[name=metodopago]:checked').val()=='contrareembolso'){
              cambiarContrareembolso();
      }
  }
}


cambiarContrareembolso = function(){
    $.ajax({
                url: ajaxTheme,
                type: "POST",
                dataType: "json",
                data: "&action=cambiarContrareembolso",
                success: function (response) {
                  //console.log('llega:: '+response);

                  contrareembolso = response['contrareembolso'];
                  contrareembolso_print = formatearImprimir(contrareembolso, 2);
                  $('#gastos_contrareembolso').html(contrareembolso_print);  

                  r_print = formatearImprimir(response['importe_pedido'], 2);
                  
                },
                error: function (xhr, status) {
                },
                complete: function (xhr, status) {
                     
                }
          });
}

alerta = function(objeto,tipo,texto,autoHide){

    objeto.removeClass("alert-success alert-info alert-warning alert-danger");
    objeto.addClass(tipo);
    objeto.find(".textoAlert").html(texto);
    
    if (autoHide){
      objeto.fadeIn().delay(4000).fadeOut();
    }else{
      objeto.fadeIn();
    }

  }


validateEmail = function(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
}

validarCP = function(valor){
//alert('valida cp');
  if (isNaN(valor) || valor.length!=5){
    return false;
  }else{
    return true;
  }

}

validarMovil = function(tlf) {
  var exr = /6\d{8}/;
  var exr2 = /7\d{8}/;
  return exr.test(tlf) || exr2.test(tlf) ;
}

validarFijo = function(tlf) {
  var exr = /9\d{8}/;
  return exr.test(tlf);
}

validarTelefono = function (tlf){
  return ( validarMovil(tlf) || validarFijo(tlf) );
}

letra_nif = function(dni){
    var numero = dni % 23 + 1;
    letra='TRWAGMYFPDXBNJZSQVHLCKET';
    letra=letra.substring(numero-1,numero);
    return letra;
}     
    
validar_nie = function(valor,numero_digitos){
     var i;
     var dni=valor;
     primera_letra = dni.charAt(0);
     if(primera_letra.toUpperCase()!="X" && primera_letra.toUpperCase()!="T" && primera_letra.toUpperCase()!="Y")
          return false;
         
     for (i = 1; i < numero_digitos; i++)
    {  
        var c = dni.charAt(i);
        if (!isDigit(c))
        {
            return false;
        }
    }
    var letra_introducido = letra_nif(dni.substring(1,numero_digitos+1));
    var letra_real = dni.charAt(numero_digitos+1);
    if (letra_introducido == letra_real.toUpperCase())
    {
        return true;
    }
    else
    {
        return false;
    }        
}

validar_nif = function(valor){
     var i;
     var dni=valor;
    
     if(dni.length<4){
          return false;    
     }
    
     for (i = 0; i < dni.length-1; i++)
    {  
        var c = dni.charAt(i);
        if (!isDigit(c))
        {
            return false;
        }
    }
    var letra_introducido = letra_nif(dni.substring(0,dni.length-1));
    var letra_real = dni.charAt(dni.length-1);
    if (letra_introducido == letra_real.toUpperCase())
    {
        return true;
    }
    else
    {
        return false;
    }
}    
    

validarDNI = function(valor) {
  //var exr = /6\d{8}/;
  //return exr.test(dni);
  return ( validar_nif(valor) || validar_nie(valor,7) || validar_nie(valor,8) );
}


validarCIF = function(valor){
  valido = true;
  err = true;
  if (valor!=""){
        var sum,
        num = [],
        controlDigit;
        
       valor = valor.toUpperCase();
        
       // Basic format test
       if ( !valor.match( '((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)' ) ) {
        err = false;
       }
        
       for ( var i = 0; i < 9; i++ ) {
        num[ i ] = parseInt( valor.charAt( i ), 10 );
       }
        
       // Algorithm for checking CIF codes
       sum = num[ 2 ] + num[ 4 ] + num[ 6 ];
       for ( var count = 1; count < 8; count += 2 ) {
        var tmp = ( 2 * num[ count ] ).toString(),
         secondDigit = tmp.charAt( 1 );
         
        sum += parseInt( tmp.charAt( 0 ), 10 ) + ( secondDigit === '' ? 0 : parseInt( secondDigit, 10 ) );
       }
        
       // CIF test
       if ( /^[ABCDEFGHJNPQRSUVW]{1}/.test( valor ) ) {
        sum += '';
        controlDigit = 10 - parseInt( sum.charAt( sum.length - 1 ), 10 );
        valor += controlDigit;
        err = ( num[ 8 ].toString() === String.fromCharCode( 64 + controlDigit ) || num[ 8 ].toString() === valor.charAt( valor.length - 1 ) );
       }
        
      // err = true;
  }


  return err;

}

isDigit = function(c){
     return ((c >= "0") && (c <= "9"))
}

validarDNICIF = function(valor){

  return ( validarDNI(valor) || validarCIF(valor) );

}



validarEnvio = function(){
  error = false;
  _p = $( "#paisEspanya" ).val();
  _recogerSolera = false;
  if($("#recogida").is(':checked')) {  
    _recogerSolera = true;
  }  
  _tipo_cliente = $('#tipo_cliente').val();

  if (_tipo_cliente == 'empresa'){
    if (!_recogerSolera && $("#formEnvio #empresa").val()==""){
      error=true;
      $("#formEnvio #empresa").parent().addClass('has-error');
    }else{
      $("#formEnvio #empresa").parent().removeClass('has-error');
    }

    if (!_recogerSolera && $("#formEnvio #establecimiento").val()==""){
      error=true;
      $("#formEnvio #establecimiento").parent().addClass('has-error');
    }else{
      $("#formEnvio #establecimiento").parent().removeClass('has-error');
    }
  }  

  if (_tipo_cliente == 'particular'){
    if (!_recogerSolera && $("#formEnvio #nombre").val()==""){
      error=true;
      $("#formEnvio #nombre").parent().addClass('has-error');
    }else{
      $("#formEnvio #nombre").parent().removeClass('has-error');
    }

    if (!_recogerSolera && $("#formEnvio #apellidos").val()==""){
      error=true;
      $("#formEnvio #apellidos").parent().addClass('has-error');
    }else{
      $("#formEnvio #apellidos").parent().removeClass('has-error');
    }
  }  

  if (!_recogerSolera && $("#formEnvio #direccion").val()==""){
    error=true;
    $("#formEnvio #direccion").parent().addClass('has-error');
  }else{
    $("#formEnvio #direccion").parent().removeClass('has-error');
  }

  if (!_recogerSolera && $("#formEnvio #pais").val()==""){
    error=true;
    $("#formEnvio #pais").parent().addClass('has-error');
  }else{
    $("#formEnvio #pais").parent().removeClass('has-error');
  }

  if (!_recogerSolera && $("#formEnvio #provincia").val()==""){
    error=true;
    $("#formEnvio #provincia").parent().addClass('has-error');
  }else{
    $("#formEnvio #provincia").parent().removeClass('has-error');
  }

  if (!_recogerSolera && $("#formEnvio #poblacion").val()==""){
    error=true;
    $("#formEnvio #poblacion").parent().addClass('has-error');
  }else{
    $("#formEnvio #poblacion").parent().removeClass('has-error');
  }

  if (!_recogerSolera && ($("#formEnvio #codigopostal").val()=="" || ($("#formEnvio #pais").val()==_p && !validarCP($("#formEnvio #codigopostal").val()) )) ){
    error=true;
    $("#formEnvio #codigopostal").parent().addClass('has-error');
  }else{
    $("#formEnvio #codigopostal").parent().removeClass('has-error');
  }

  if (!_recogerSolera && (!validarFijo($("#formEnvio #telefono").val()) && !validarMovil($("#formEnvio #telefono").val()))){
    error=true;
    $("#formEnvio #telefono").parent().addClass('has-error');
  }else{
    $("#formEnvio #telefono").parent().removeClass('has-error');
  }

  return error;
}

validarFacturacion = function(){
  error = false;
  _p = $( "#paisEspanya" ).val();
  _tipo_cliente = $('#tipo_cliente').val();
  if (_tipo_cliente == 'empresa'){
    if ($("#formFacturacion #empresa_fact").val()==""){
      error=true;
      $("#formFacturacion #empresa_fact").parent().addClass('has-error');
    }else{
      $("#formFacturacion #empresa_fact").parent().removeClass('has-error');
    }
  }
    
  if ($("#formFacturacion #cif_fact").val()=="" || ($("#formFacturacion #pais_fact").val()==_p && !validarDNICIF($("#formFacturacion #cif_fact").val())) ){
    error=true;
    $("#formFacturacion #cif_fact").parent().addClass('has-error');
  }else{
    $("#formFacturacion #cif_fact").parent().removeClass('has-error');
  }

  if (_tipo_cliente == 'empresa'){
    if ($("#formFacturacion #nombre_fact").val()==""){
      error=true;
      $("#formFacturacion #nombre_fact").parent().addClass('has-error');
    }else{
      $("#formFacturacion #nombre_fact").parent().removeClass('has-error');
    }

    if ($("#formFacturacion #apellidos_fact").val()==""){
      error=true;
      $("#formFacturacion #apellidos_fact").parent().addClass('has-error');
    }else{
      $("#formFacturacion #apellidos_fact").parent().removeClass('has-error');
    }
  }  

  if ($("#formFacturacion #direccion_fact").val()==""){
    error=true;
    $("#formFacturacion #direccion_fact").parent().addClass('has-error');
  }else{
    $("#formFacturacion #direccion_fact").parent().removeClass('has-error');
  }

  if ($("#formFacturacion #pais_fact").val()==""){
    error=true;
    $("#formFacturacion #pais_fact").parent().addClass('has-error');
  }else{
    $("#formFacturacion #pais_fact").parent().removeClass('has-error');
  }

  if ($("#formFacturacion #provincia_fact").val()==""){
    error=true;
    $("#formFacturacion #provincia_fact").parent().addClass('has-error');
  }else{
    $("#formFacturacion #provincia_fact").parent().removeClass('has-error');
  }

  if ($("#formFacturacion #poblacion_fact").val()==""){
    error=true;
    $("#formFacturacion #poblacion_fact").parent().addClass('has-error');
  }else{
    $("#formFacturacion #poblacion_fact").parent().removeClass('has-error');
  }

  if ($("#formFacturacion #codigopostal_fact").val()=="" || ($("#formFacturacion #pais_fact").val()==_p && !validarCP($("#formFacturacion #codigopostal_fact").val()) ) ){
    error=true;
    $("#formFacturacion #codigopostal_fact").parent().addClass('has-error');
  }else{
    $("#formFacturacion #codigopostal_fact").parent().removeClass('has-error');
  }

  if (!validarFijo($("#formFacturacion #telefono_fact").val()) && !validarMovil($("#formFacturacion #telefono_fact").val())){
    error=true;
    $("#formFacturacion #telefono_fact").parent().addClass('has-error');
  }else{
    $("#formFacturacion #telefono_fact").parent().removeClass('has-error');
  }

  return error;
}

validarTipoEnvio = function(){
  error = false;
  if ($('input:radio[name=envio]:checked').val() == '' || $('input:radio[name=envio]:checked').val() == 'undefined' || $('input:radio[name=envio]:checked').val() == undefined){
    error = true;
  }
  return error;
}

validarMetodoPago = function(){
  
  error = false;
  if ($('input:radio[name=metodopago]:checked').val() == '' || $('input:radio[name=metodopago]:checked').val() == 'undefined' || $('input:radio[name=metodopago]:checked').val() == undefined){
    //miro si hay tarjeta
    // if($('#storedcard').val()=='0'){
    //   if(
    //     $('#newcard_titular').val()=='' ||
    //     $('#newcard_tipo').val()=='' ||
    //     $('#newcard_numero').val()=='' ||
    //     $('#newcard_expire_month').val()=='' ||
    //     $('#newcard_expire_year').val()=='' ||
    //     $('#newcard_cvv').val()==''        
    //   ){

    //     error = true;      
    //   }
    // }
    error = true;
  }
  else {
    if ($('input:radio[name=metodopago]:checked').val() == 'transferenciabanco' && $('#numero_cuenta').val() == ''){
      error = true;
    }
  }
  return error;
}


enviarTVP = function(){
  document.location.href="/pago-tpv";
  //$('#js-spinner-compra').spin('hide');
}

enviarTPVDefinitivo = function(){
  $('#fPedido').attr('action', '/pago-tpv');
  $('#fPedido').submit();
}

enviarEmail = function(){
    $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: "&action=enviarEmailGracias",
        success: function (response) {
          //console.log(response['resultado']);
            if (response['resultado'] == 'OK'){
                document.location.href='/gracias-compra/';
            }
            else{
                mostrar_msg('#myModal', 'Error', response['mensaje']);
            }
            $('#js-spinner-compra').spin('hide');
        },
        error: function (xhr, status) {
           

        },
        complete: function (xhr, status) {
             
        }
    });  
}
calcularGastosEnvioEstimado = function(){
  _idproducto = $('#idproducto-cesta').val();
  _idformato = $('#formato_idformato-cesta').val();
  _unidades = $('#item-cantidad').val();
    $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: $('#formCalcularGastosEnvio').serialize()+"&idproducto="+_idproducto+"&idformato="+_idformato+"&unidades="+_unidades,
        success: function (response) {
            $('#CosteResultadoGastosEnvio').html(response.toFixed(2));
        },
        error: function (xhr, status) {
           

        },
        complete: function (xhr, status) {
             
        }
    });  
}
guardarPedido = function(_tipoenvio, _pais, _provincia, _recoger, _contrareembolso, _recargoequivalencia){
  _recogerSolera = '0';
  if( $('#recogida').prop('checked') ) {
    _recogerSolera = '1';
  } 
 // calcularTotalPedido(_recargoequivalencia);
  $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: $('#fPedido').serialize(),
        success: function (response) {
          
          if (response['resultado'] == 'OK'){
                if ($('input:radio[name=metodopago]:checked').val()=='transferenciabanco' || $('input:radio[name=metodopago]:checked').val()=='contrareembolso'){
                    enviarEmail();
                }
                else {
                  enviarTVP();
                }
                
            }
            else{
                mostrar_msg('#myModal', 'Error', response['msg']);
                ocultarSpin();
                $('#btn-comprar').attr("disabled", false);
            }

        },
        error: function (xhr, status) {
           

        },
        complete: function (xhr, status) {
             
        }
    });

}

guardarPedidoProduccion = function(_tipoenvio, _pais, _provincia, _recoger, _contrareembolso, _recargoequivalencia){
  _recogerSolera = '0';
  if( $('#recogida').prop('checked') ) {
    _recogerSolera = '1';
  } 
 // calcularTotalPedido(_recargoequivalencia);
  if ($('input:radio[name=metodopago]:checked').val()=='transferenciabanco' || $('input:radio[name=metodopago]:checked').val()=='contrareembolso'){
          $('#action').val('guardarPedido');
          $.ajax({
            url: ajaxTheme,
            type: "POST",
            dataType: "json",
            data: $('#fPedido').serialize()+'&tipo=n',
            success: function (response) {
              
                if (response['resultado'] == 'OK'){
                        enviarEmail();
                   
                }
                else{
                    mostrar_msg('#myModal', 'Error', response['msg']);
                    ocultarSpin();
                    $('#btn-comprar').attr("disabled", false);
                }
            },
            error: function (xhr, status) {
               

            },
            complete: function (xhr, status) {
                 
            }
        });
  }    
  else {//tarjeta, envía al TPV y en el callback hace la llamada a guardarpedido
    // enviarTPVDefinitivo();
    //con el nuevo tpv, cambio el action para usar una funcion distinta que lo hace todo.
    $('#action').val('tpvCompleto');
    $('#fPedido').attr('action', tpvRediretUrl);
    $('#fPedido').submit();
    /*
    $.ajax({
        url: ajaxTheme,
        type: "POST",
        dataType: "json",
        data: $('#fPedido').serialize(),
        success: function (response) {
          
            if (response['resultado'] == 'OK'){
                self.location.href='/gracias-compra/';
            }
            else{
                mostrar_msg('#myModal', 'Error','Ha ocurrido un problema con esta tarjeta. <br><br><span style="font-size:14px">'+ response['msg']+"</span>");
                ocultarSpin();
                $('#btn-comprar').attr("disabled", false);
            }
        }
    });
    */
  }    

}

validarContacta = function(){
  error = false;
  if (!$('#ckpolitica').prop('checked')){
    error=true;
    $("#mensaje").parent().addClass('has-error');
  }else{
    $("#mensaje").parent().removeClass('has-error');
  }

  if ($('#mensaje').val() == ''){
    error=true;
    $("#mensaje").parent().addClass('has-error');
  }else{
    $("#mensaje").parent().removeClass('has-error');
  }

  if (!validateEmail($('#email').val())){
    error=true;
    $("#email").parent().addClass('has-error');
  }else{
    $("#email").parent().removeClass('has-error');
  }
 
  if ($("#telefono").val()!='' && !validarFijo($("#telefono").val()) && !validarMovil($("#telefono").val())){
    error=true;
    $("#telefono").parent().addClass('has-error');
  }else{
    $("#telefono").parent().removeClass('has-error');
  }

  return error;
}

validarFormato = function(){
  _error = true;
  if ($('#selformato option:selected').attr('stock') == '3'){
                mostrar_msg("#myModal", 'Sin Stock', 'Producto agotado.');
              }
              else {
                  _errorOpciones = false;
                
                  _formatoEl = $('#selformato').val();
                  $( ".sabores_"+_formatoEl ).each(function( index ) {
                      if ($(this).val() == ''){
                        _errorOpciones = true;
                      }
                  });

                  if (_errorOpciones){
                        mostrar_msg("#myModal", 'Error', 'Debes elegir los sabores del producto.');
                  }
                  else{
                      _errorRegalos = false;
                      $( ".regalos-opciones" ).each(function( index ) {
                          if ($(this).val() == ''){
                            _errorRegalos = true;
                          }
                      });

                      if (_errorRegalos){
                          mostrar_msg("#myModal", 'Error', 'Debes elegir las opciones del regalo.');
                      }
                      else {
                          _error = false;
                            
                      }//else 3    
                  }//else 2    
     }//else 1  
     return _error;
}

  cestaVacia = function(){
     _vacia = false;
     if (_cestaCarrito == null || _cestaCarrito == 'null' || _cestaCarrito == 'undefined' || _cestaCarrito == undefined || _cestaCarrito == ''){
        _vacia = true;
     }
     else {
        if (_cestaCarrito['items'] == null || _cestaCarrito['items'] == 'null' || _cestaCarrito['items'] == 'undefined' || _cestaCarrito['items'] == undefined || _cestaCarrito['items'] == ''){
            _vacia = true;
        }
     }
     return _vacia; 
  }

  mostrarSpin = function(){
    $('#modal_spin').show();
    $('.spin').spin('show');
  }
  ocultarSpin = function(){
   $('.spin').spin('hide'); 
   $('#modal_spin').hide();
  }

  mostrarGastosEnvio = function(){
    calcularGastosEnvioEstimado();
    $('#modal-calcular-gastos').modal('show');
  }



})(jQuery);

function loader_spinner(_target,_status){
    //target por ID porque se hace por DOM javascript y NO jquery
    var targetSpinner= document.getElementById(_target);
    var targetJquery=jQuery("#"+_target);
    if(targetJquery.hasClass("js-spinner-holder")){
        targetFade=".js-spinner-holder"
    }else{
        targetFade="#"+_target;
    }

    if(_status=="show"){
        jQuery(targetSpinner).html("");
        jQuery(targetSpinner).spin();
        jQuery(targetSpinner).spin('show');
        jQuery(targetFade).fadeIn();
    }else{
        jQuery(targetFade).fadeOut(function(){
            jQuery(targetSpinner).html("");
            jQuery(targetSpinner).spin('hide');
        });
    }

}

var formatNumber = {
 separador: ".", // separador para los miles
 sepDecimal: ',', // separador para los decimales
 formatear:function (num){
  num +='';
  var splitStr = num.split('.');
  var splitLeft = splitStr[0];
  var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
  var regx = /(\d+)(\d{3})/;
  while (regx.test(splitLeft)) {
  splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
  }
  return this.simbol + splitLeft  +splitRight;
 },
 new:function(num, simbol){
  this.simbol = simbol ||'';
  return this.formatear(num);
 }
}

function pasarJS(_precio){
  _precio = _precio.replace(".", "");
  _precio = _precio.replace(",", ".");
  return _precio;
}

function formatearImprimir(_valor, _decimales){
  _valor = _valor.toFixed(_decimales);
  _valor = formatNumber.new(_valor);
  return _valor;
}
